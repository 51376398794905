import { Fragment, useState, useEffect } from "react";
import { useTranslation } from 'react-i18next';
import download from "downloadjs";
import numeral from "numeral";

import ImageFusionUpload from "./ImageFusionUpload";
import ImageFileNameChange from "../ImageFileNameChange/ImageFileNameChange";
import ImageFileUpload from '../ImageFileUpload/ImageFileUpload';
import ShareEditImage from "../ShareEditImage/ShareEditImage";

import { useStore } from '../../hook-store/store';

import { FUSIONAPI_URL } from "../../App";

import { marks } from '../../utils/marks';
import "./ImageFusion.css";

export default function ImageFusionResult(props) {
  const { 
    fusionFile,
    setFusionFile,
    inputFile,
    inputFile2,
   } = props;

  const [t] = useTranslation('translation');

  const [store, dispatch] = useStore();
  // const { runCycle, isUserFusionRunning } = store.imageFusionStore;

  const [displayWidth, setDisplayWidth] = useState(0);
  const [showNameChangeModal, setShowNameChangeModal] = useState(false);
  const [showInputUploadModal, setShowInputUploadModal] = useState(false);


  useEffect(() => {
    if (fusionFile) {
      setDisplayWidth(1);
    }
  },[fusionFile]);

  const fileDownloadHandler = (file, type) => {
    // const fileType = file.type;
    // const fileName = `fusion-${Date.now()}.${fileType.split("/")[1]}`;

    download(file, file.name, file.type);
  };

  const createShareFileHandler = () => {
    return fusionFile;
  }

  const fileNameUpdateHandler = (file) => {
    setFusionFile(file);
  };

  // const getImgDimension = (event, inputNum) => {
  //   // console.log(event, event.target.naturalHeight, event.target.naturalWidth);

  //   // console.log(event, event.target.naturalHeight, event.target.naturalWidth);
  //   if (inputNum === 1) {
  //     if (
  //       event.target.naturalHeight !== fileDimension.height ||
  //       event.target.naturalWidth !== fileDimension.width
  //     ) {
  //       setFileDimension({
  //         height: event.target.naturalHeight,
  //         width: event.target.naturalWidth,
  //       });
  //     }
  //   } else {
  //     if (
  //       event.target.naturalHeight !== fileDimension2.height ||
  //       event.target.naturalWidth !== fileDimension2.width
  //     ) {
  //       setFileDimension2({
  //         height: event.target.naturalHeight,
  //         width: event.target.naturalWidth,
  //       });
  //     }
  //   }
  // };


  let resultImageStyle = {
    maxWidth: "100%"
  }

  if (displayWidth === 0) {
    resultImageStyle = null;
  }

  return (
    <Fragment>
      {fusionFile && (
        <div>
          <section className="fusionSectoin">
            <div>{t('imageFusion09', 'fusion image')}</div>
            <img
              // className="combinerInputImage"
              style={resultImageStyle}
              // title={inputFile.name}
              src={URL.createObjectURL(fusionFile)}
              alt="fusion result"
              onClick={() => {}}
              // onLoad={(event) => {
              //   getImgDimension(event, 1);
              // }}
            />

            <div>
              <button className="btnBase"
                style={{fontSize: "0.9rem"}}
                onClick={() => {
                  setDisplayWidth(0);
                }}
              >
                {t('imageFusion10', 'Original size')}
              </button>
              <button className="btnBase"
                style={{fontSize: "0.9rem"}}
                onClick={() => {
                  setDisplayWidth(1);
                }}
              >
                {t('imageFusion11', 'Fit screen')}
              </button>
            </div>
          </section>

          <section>
            <div className="fusionSectoin">
              <div>{t('imageFusion26', 'File name')}</div>
              <div>
                {fusionFile.name} ({numeral(fusionFile.size).format('0.0 b')})
              </div>
            </div>
            <div className="">
              <button
                className="btnBase"
                onClick={() => {
                  setShowNameChangeModal(true);
                }}
              >
                {t('fileAction.06', 'Change Name')}
              </button>
            </div>
            {showNameChangeModal && (
              <div className="fusionSectoin">
                <ImageFileNameChange 
                  currentRenderFile={fusionFile}
                  createUploadFile={fileNameUpdateHandler}
                  onClose={() => { setShowNameChangeModal(false); }}
                />
              </div>
            )}
            <div className="fusionSectoin"></div>
            <div className="fusionSectoin"></div>
            <div className="fusionSectoin"></div>
          </section>

          <div className="fusionSection">
            <div className="fusionActionButtons">
              <button className="btnBase"
                onClick={() => { 
                  fileDownloadHandler(fusionFile); 
                }}
              >
                {marks.downloadMark} {t('imageFusion12', 'download file')}
              </button>
              <ImageFusionUpload 
                fusionFile={fusionFile}
              />

              <ShareEditImage
                createShareFile={createShareFileHandler}
                fileForShare={fusionFile}
              />
            </div>
          </div>
          
          {/* {inputFile && inputFile2 && (
            <div>
            <img style={{height: "100px"}}
              src={URL.createObjectURL(inputFile)}
            />
            <img style={{height: "100px"}}
              src={URL.createObjectURL(inputFile2)}
            />
            </div>
          )} */}
          <div className='fusionSection'>
              <button className='btnBase'
                disabled={!inputFile || !inputFile2}
                onClick={() => {
                  setShowInputUploadModal(true);
                }}
              >
                {marks.uploadMark} {t('imageFusion27', 'Upload input files')}
              </button>
          
              {showInputUploadModal && (
                <ImageFileUpload 
                  files={[inputFile, inputFile2]}
                  onClose={() => { 
                    setShowInputUploadModal(false) 
                  }}
                  uploadApp={'imageFusion'}
                />
              )}
            </div>
        </div>
      )}
    </Fragment>
  );
}
