import React, { Fragment, } from 'react'
import * as Progress from '@radix-ui/react-progress'
import { useRecoilValue } from 'recoil'
import io from 'socket.io-client'
import { isInpaintingState, isSDState, settingState } from '../../store/Atoms.tsx'

import { API_ENDPOINT } from '../../adapters/inpainting.ts';

import { useStore } from '../../../../hook-store/store';

const isDev = process.env.NODE_ENV === 'development'
// const socket = isDev ? io(`http://localhost:8080`) : io()
// const socket = io(API_ENDPOINT);
// let socket

const LamaProgress = () => {
  const isSD = useRecoilValue(isSDState)
  const isInpainting = useRecoilValue(isInpaintingState)
  const [isConnected, setIsConnected] = React.useState(false)
  const [step, setStep] = React.useState(0)
  const setting = useRecoilValue(settingState)
  const maxStep = Math.max(setting.sdSteps, 1)

  const progress = Math.min(Math.round((step / maxStep) * 100), 100)

  const [store, dispatch] = useStore();
  const { lamaProcessing } = store.lamaCleanerStore;
  // React.useEffect(() => {
  //   if (!isSD) return

  //   socket.on('connect', () => {
  //     setIsConnected(true)
  //   })

  //   socket.on('disconnect', () => {
  //     setIsConnected(false)
  //   })

  //   socket.on('diffusion_progress', data => {
  //     console.log(`step: ${data.step + 1}`)
  //     if (data) {
  //       setStep(data.step + 1)
  //     }
  //   })

  //   socket.on('diffusion_finish', data => {
  //     console.log('diffusion_finish data', data)
  //     setStep(0)
  //   })

  //   return () => {
  //     socket.off('connect')
  //     socket.off('disconnect')
  //     socket.off('diffusion_progress')
  //     socket.off('diffusion_finish')
  //   }
  // }, [isSD])

  React.useEffect(() => {
    const socket = io(API_ENDPOINT);
    
    if (!isSD) {
      socket.on('connect', () => {
        console.log('socket connect');
        setIsConnected(true)
      })
  
      socket.on('disconnect', () => {
        console.log('socket disconnect');
        setIsConnected(false)
      })
  
      // socket.on('diffusion_progress', data => {
      //   console.log(`step: ${data.step + 1}`)
      //   if (data) {
      //     setStep(data.step + 1)
      //   }
      // })
  
      // socket.on('diffusion_finish', data => {
      //   console.log('diffusion_finish data', data)
      //   setStep(0)
      // })
  
      socket.on('processing_start', data => {
        console.log('processing_start, data', data);
        //@ts-ignore
        dispatch('SET_LAMAPROCESSING', true);
        //@ts-ignore
        dispatch('SHOW_NOTIFICATION', {
          status: '',
          title: 'Image processing server is busy ...',
          message: '',
        });
      });
  
      socket.on('processing', data => {
        console.log('processing, data', data);
        //@ts-ignore
        dispatch('SET_LAMAPROCESSING', true);
        //@ts-ignore
        dispatch('SHOW_NOTIFICATION', {
          status: '',
          title: 'Image processing server is busy ...',
          message: '',
        });
      });

      socket.on('processing_error', data => {
        console.log('processing_error, data', data);
        //@ts-ignore
        dispatch('SET_LAMAPROCESSING', false);
        //@ts-ignore
        dispatch('CLEAR_NOTIFICATION');
      });
  
      socket.on('processing_finish', data => {
        console.log('processing_end, data', data);
        //@ts-ignore
        dispatch('SET_LAMAPROCESSING', false);
        //@ts-ignore
        dispatch('CLEAR_NOTIFICATION');
      })
  
      return () => {
        socket.off('connect')
        socket.off('disconnect')
        // socket.off('diffusion_progress')
        // socket.off('diffusion_finish')
        socket.off('processing_start')
        socket.off('processing')
        socket.off('processing_finish')

        //@ts-ignore
        dispatch('SET_LAMAPROCESSING', false);
      }
    }
  }, [isSD])

  return (
    <Fragment>
      {/* {lamaProcessing && (
        <div 
          style={{
            position:"fixed", 
            // color:"red", 
            bottom: "5%",
            right: "10px", 
            fontSize: "1rem",
            zIndex:"100",
          }}
        >
          server-is-busy
        </div>
      )} */}
    </Fragment>
    // <div
    //   className="ProgressWrapper"
    //   style={{
    //     visibility: isInpainting && isConnected && isSD ? 'visible' : 'hidden',
    //   }}
    // >
    //   <Progress.Root value={progress} className="ProgressRoot">
    //     <Progress.Indicator
    //       className="ProgressIndicator"
    //       style={{ transform: `translateX(-${100 - progress}%)` }}
    //     />
    //   </Progress.Root>
    //   <div
    //     style={{
    //       width: 45,
    //       display: 'flex',
    //       justifyContent: 'center',
    //       fontVariantNumeric: 'tabular-nums',
    //     }}
    //   >
    //     {progress}%
    //   </div>
    // </div>
  )
}

export default LamaProgress
