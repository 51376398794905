import * as React from "react";
import { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import Backdrop from "../Backdrop/Backdrop";
import ModalConfirm from "../Modal/ModalConfirm";

import { useStore } from "../../hook-store/store";

import { marks } from "../../utils/marks";

import "./ImageFileNameChange.css";
// import classes from './UploadImage.module.css';

const ImageFileNameChange = (props) => {
  const { 
    currentRenderFile,
    createUploadFile,
    onClose,
   } = props;

  const [t] = useTranslation("translation");

  const [store, dispatch] = useStore();
  const { gLoading, } = store.uiStore;

  const [fileNameInput, setFileNameInput] = useState("");
  const [inputError, setInputError] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (gLoading) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [gLoading]);


  const createUpdateNameHandler = (event) => {
    setInputError("");
    const input = event.target.value.trim();

    console.log(input.length);
    if (!input.length) {
      setInputError("name is required");
      return;
    }
    if (input.length > 100) {
      setInputError("maximum name length is 100");
      return;
    }

    if (input.includes("/")) {
      setInputError('name should not have "/"');
      return;
    }

    setFileNameInput(input);
  };

  const updateFileName = (file, fileNameInput) => {
    if (!fileNameInput || !file) {
      return;
    }

    // let fileSplit = "";
    // let fileExt = "";
    // let fileName = "";
    // let uploadFile;

    // //// file data object case { id: xxx, file: File }
    // if (file.id) {
    //   const cfile = file.file;
    //   // console.log(cfile)
    //   fileSplit = cfile.name.split(".");
    //   fileExt = fileSplit.pop();

    //   fileName = fileNameInput + "." + fileExt;

    //   // console.log(name);
    //   // throw new Error('error-error');
    //   uploadFile = {
    //     id: file.id,
    //     file: new File([cfile], fileName, { type: cfile.type }),
    //   };

    //   //// File case
    // } else {
    //   fileSplit = file.name.split(".");
    //   fileExt = fileSplit.pop();

    //   fileName = fileNameInput + "." + fileExt;

    //   // console.log(name);
    //   // throw new Error('error-error');

    //   uploadFile = new File([file], fileName, { type: file.type });
    // }

    const fileSplit = file.name.split(".");
    const fileExt = fileSplit.pop();

    const fileName = fileNameInput + "." + fileExt;

    const uploadFile = new File([file], fileName, { type: file.type });

    createUploadFile(uploadFile);
    onClose();

  };

  let fileExt = "";
  let defaultDisplayName = '';

  if (currentRenderFile.name) {
    const fileSplit = currentRenderFile.name.split(".");
    fileExt = fileSplit.pop();

    defaultDisplayName = fileSplit.join('.');
  }

  let imageFileNameChangeBody;

  imageFileNameChangeBody = (
    <div className="">
      <div>
        <Backdrop
          zIndex={"95"}
          onCancel={() => {
            if (!isLoading) {
              onClose();
            }
          }}
        />
        <ModalConfirm
          hideButtons={true}
          onClose={() => {
            if (!isLoading) {
              onClose();
            }
          }}
        >
          <div>
            <div className="imageFileNameChangeRow">
              {/* <div>
                <label>
                  {t("fileUploadAction02", "name input")} (
                  {t("fileUploadAction03", "max 100 characters")})
                </label>
              </div> */}
              <input
                className="inputBase"
                style={{ maxWidth: "50%" }}
                type="text"
                autoFocus
                defaultValue={defaultDisplayName}
                // placeholder='enter file name here'
                // value={fileNameInput}
                onChange={createUpdateNameHandler}
              />
              <span>.{fileExt}</span>

              <div>{inputError}</div>
              {/* {fileNameInput} */}
            </div>

            <div className="imageFileNameChangeRow">
              <button
                className="btnBase"
                disabled={isLoading || inputError || !fileNameInput}
                onClick={() => {
                  updateFileName(currentRenderFile, fileNameInput);
                }}
              >
                {t('fileAction.06', 'Change Name')}
              </button>
            </div>
          </div>
        </ModalConfirm>
      </div>
    </div>
  );

  return (
    <Fragment>
      <div>{imageFileNameChangeBody}</div>
    </Fragment>
  );
};

export default ImageFileNameChange;
