import { Fragment, useState, useEffect } from "react";
import { useTranslation } from 'react-i18next';
import download from "downloadjs";
import numeral from "numeral";

import CombinerUpload from "./CombinerUpload";
import ShareEditImage from "../ShareEditImage/ShareEditImage";
import ImageFileNameChange from "../ImageFileNameChange/ImageFileNameChange";
import ImageFileUpload from '../ImageFileUpload/ImageFileUpload';

import { useStore } from "../../hook-store/store";

import { loginPageLink, signupPageLink } from "../../App";

import { marks } from "../../utils/marks";
import './ImageCombiner.css';

export default function CombinerResult(props) {
  const { 
    combinedB64String,
    combinerInputFiles,
  } = props;

  const [t] = useTranslation('translation');

  const [store, dispatch] = useStore();
  const { isAuth } = store.uiStore;

  const [showSize, setShowSize] = useState(0);
  const [combineDimension, setCombineDimension] = useState({
    height: null,
    width: null,
  });
  const [combinedFile, setCombinedFile] = useState();
  const [showNameChangeModal, setShowNameChangeModal] = useState(false);
  const [showInputUploadModal, setShowInputUploadModal] = useState(false);

  //// reset combine image size
  useEffect(() => {
    if (window.innerWidth < 480 || window.innerHeight < 480) {
      setShowSize(-1);
    } else {
      setShowSize(0);
    }
  },[combinedB64String]);

  useEffect(() => {
    if (combinedB64String) {
      base64ToFileHandler(combinedB64String);
    }
  },[combinedB64String]);

  // const fileDownloadHandler = (resizedFileString, type) => {
  //   const fileData = resizedFileString.split(";")[0];
  //   const fileType = fileData.split(":")[1];
  //   const fileName = `combine-${Date.now()}.${fileType.split("/")[1]}`;

  //   download(resizedFileString, fileName, fileType);
  // };

  const fileDownloadHandler = (file) => {
    // const fileData = resizedFileString.split(";")[0];
    // const fileType = fileData.split(":")[1];
    // const fileName = `combine-${Date.now()}.${fileType.split("/")[1]}`;

    download(file, file.name, file.type);
  };

  const base64ToFileHandler = async (b64) => {
    try {
      const fileData = b64.split(";")[0];
      const fileType = fileData.split(":")[1];
      const fileName = `combine-${Date.now()}.${fileType.split("/")[1]}`;

      const result = await fetch(b64);
      const resData = await result.blob();
      const file = new File([resData], fileName, { type: fileType });
    //   console.log(file);

      if (file) {
        setCombinedFile(file);
      }

      return file;
    } catch (err) {
      console.log(err);
      throw err;
    }
  };

  const getCombineDimension = (event) => {
    if (
      event.target.naturalHeight !== combineDimension.height ||
      event.target.naturalWidth !== combineDimension.width
    ) {
      setCombineDimension({
        height: event.target.naturalHeight,
        width: event.target.naturalWidth,
      });
    }
  };

  const createShareFileHandler = () => {
    return combinedFile;
  }

  let combineStyle = { height: "100%" };

  if (showSize === -1) {
    combineStyle = { 
      maxHeight: "100%", 
      maxWidth: "100%"
    }
  }

  if (showSize === 0) {
    combineStyle = { height: "100%" };
  }

  if (showSize > 0) {
    combineStyle = { height: `${showSize}px` };
  }

  const fileNameUpdateHandler = (file) => {
    setCombinedFile(file);
  };

  return (
    <Fragment>
      {combinedB64String && combinedFile && (
        <div>
          <section className="combinerSectoin">
            <img
              // className="resizerImage"
              style={combineStyle}
              //   title={item.file.name}
              src={combinedB64String}
              alt="for resize"
              onClick={() => {}}
              onLoad={getCombineDimension}
            />
            {combineDimension.height > 0 && showSize === 0 && (
              <div className="combinerImageDeminsions">
                (H: {combineDimension.height}, W: {combineDimension.width})
              </div>
            )}
          </section>

          <section className="combinerSectoin">
            <div className="combinerInputButtons">
              <button className="btnBase"
                onClick={() => {
                  setShowSize(0);
                  //   setShowOriginalSize(!showOriginalSize);
                }}
              >
                {t('combiner05', 'Reset size')}
              </button>
              <button className="btnBase"
                onClick={() => {
                  setShowSize(-1);
                }}
              >
                Fit screen
              </button>
              <button className="btnBase"
                onClick={() => {
                  setShowSize(100);
                }}
              >
                {t('combiner06', 'Change display size')} (H: 100)
              </button>
              <button className="btnBase"
                onClick={() => {
                  setShowSize(300);
                }}
              >
                {t('combiner06', 'Change display size')} (H: 300)
              </button>
              <button className="btnBase"
                onClick={() => {
                  setShowSize(600);
                }}
              >
                {t('combiner06', 'Change display size')} (H: 600)
              </button>
              <div>
                
                <input className="inputBase"
                  type="text"
                  placeholder={t('combiner07', 'enter height for display')}
                  onChange={(event) => {
                    setShowSize(Math.floor(Number(event.target.value)));
                  }}
                />
              </div>
            </div>
          </section>



          <div className="combinerSectoin">
            <div>{t('combiner16', 'File name')}</div>
            <div>
              {combinedFile.name} ({numeral(combinedFile.size).format('0.0 b')})
            </div>
          </div>

          <div className="">
            <button
              className="btnBase"
              onClick={() => {
                setShowNameChangeModal(true);
              }}
            >
              {t('fileAction.06', 'Change Name')}
            </button>
          </div>
          {showNameChangeModal && (
            <div className="combinerSectoin">
              <ImageFileNameChange 
                currentRenderFile={combinedFile}
                createUploadFile={fileNameUpdateHandler}
                onClose={() => { setShowNameChangeModal(false); }}
              />
            </div>
          )}
          <div className="combinerSectoin"></div>
          <div className="combinerSectoin"></div>
          <div className="combinerSectoin"></div>
        


          <div className="combinerActionButtons">
            <button className="btnBase"
              onClick={() => {
                // fileDownloadHandler(combinedB64String);
                fileDownloadHandler(combinedFile);
              }}
            >
              {marks.downloadMark} {t('combiner08', 'Download')}
            </button>

            <CombinerUpload 
              combinedB64String={combinedB64String}
              combinedFile={combinedFile}
            />

            <ShareEditImage
              createShareFile={createShareFileHandler}
              fileForShare={combinedFile}
            />
          </div>

          <div className='imageConvertElement'>
              <button className='btnBase'
                disabled={!combinerInputFiles.length === 0}
                onClick={() => {
                  setShowInputUploadModal(true);
                }}
              >
                {marks.uploadMark} {t('combiner17', 'Upload input files')}
              </button>
          
              {showInputUploadModal && (
                <ImageFileUpload 
                  files={combinerInputFiles}
                  onClose={() => { 
                    setShowInputUploadModal(false) 
                  }}
                  uploadApp={'imageCombiner'}
                />
              )}
            </div>
        </div>
      )}

      {!isAuth && (
        <div>
          <p>
            {t('aboutPage.09', 'Login to upload and store files, or create account')}
          </p>

          <div className="resizerResizeLoginButtons">
            <a href={loginPageLink}>
              <button className="btnBase">
                {marks.signInMrak} {t('aboutPage.10', 'Login')}
              </button>
            </a>
            <span> {t('aboutPage.11', 'or')} </span>
            <a href={signupPageLink}>
              <button className="btnBase">
                {marks.userPlusMrak} {t('aboutPage.12', 'Signup')}
              </button>
            </a>
          </div>
        </div>
      )}
    </Fragment>
  );
}
