import { Fragment, useState, useEffect, useRef } from "react";
import axios from "axios";
import { useTranslation } from 'react-i18next';
import numeral from 'numeral';

import Backdrop from "../Backdrop/Backdrop";
import CombinerInfo from "./CombinerInfo";
import CombinerFileSelectContents from "./CombinerFileSelectContents";
import CombinerFileEdit from "./CombinerFileEdit";
import ImageConvert from "../ImageConvert/ImageConvert";
import Modal from "../Modal/Modal";

import { useStore } from '../../hook-store/store';
import { changeImageDimensionSize } from '../../utils/image-util'

import { BACKEND_URL } from "../../App";

import { marks } from '../../utils/marks';
import "./ImageCombiner.css";

export default function CombinerInputs(props) {
  const { 
    setCombinedB64String,
    setCombinerInputFiles,
  } = props;

  const [t] = useTranslation('translation');

  const hiddenFileInput = useRef(null);
  const hiddenFileInput2 = useRef(null);

  const [store, dispatch] = useStore();
  const { 
    // isAuth, 
    gLoading 
  } = store.uiStore;


  const [inputFile, setInputFile] = useState();
  const [inputFile2, setInputFile2] = useState();
  const [inputFiles, setInputFiles] = useState([]);
  const [selectedFileKind, setSelectedFileKind] = useState('one');

  const [fileBlob, setFileBlob] = useState();
  const [fileBlob2, setFileBlob2] = useState();

  // const [combineBlob, setCombineBlob] = useState();
  //   const [combinedB64String, setCombinedB64String] = useState("");
  // const [showOriginalSize, setShowOriginalSize] = useState(true);
  // const [showSize, setShowSize] = useState(0);
  const [fileDimension, setFileDimension] = useState({
    height: null,
    width: null,
  });
  const [fileDimension2, setFileDimension2] = useState({
    height: null,
    width: null,
  });
  const [showCombinerInfo, setShowCombinerInfo] = useState(false);
  const [showFileSelectModal, setShowFileSelectModal] = useState(false);
  const [showImageConvert, setShowImageConvert] = useState(false);
  const [editSelectedNo, setEditSelectedNo] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  // console.log(inputFile, inputFile2, inputFiles);

  useEffect(() => {
    if (gLoading) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  },[gLoading]);

  useEffect(() => {
    if (inputFile) {
      setInputFiles([inputFile]);
    }
    if (inputFile2) {
      setInputFiles([inputFile2]);
    }
    if (inputFile && inputFile2) {
      setInputFiles([inputFile, inputFile2]);
    }
  },[inputFile, inputFile2]);

  useEffect(() => {
    setCombinerInputFiles(inputFiles);
  },[inputFiles]);


  const handleInputClick = (event, inputNum) => {
    if (inputNum === 1) {
      hiddenFileInput.current.click();
    } else {
      hiddenFileInput2.current.click();
    }
  };

  const blobProcessHandler = (event, inputNum) => {
    const fr = new FileReader();
    //@ts-ignore
    let file;

    if (inputNum === 1) {
      file = document.getElementById("fileinput").files[0];
      setInputFile(file);
    } else {
      file = document.getElementById("fileinput2").files[0];
      setInputFile2(file);
    }

    fr.readAsArrayBuffer(file);
    fr.onload = async function () {
      // you can keep blob or save blob to another position
      const blob = new Blob([fr.result]);

      console.log(blob);
      //@ts-ignore

      if (inputNum === 1) {
        setFileBlob(blob);
      } else {
        setFileBlob2(blob);
      }
    };
  };

  const getCombinedHandler = async (url, token, selectedFiles) => {
    try {
      dispatch('SET_GLOADING', true);
      const data = new FormData();

      // console.log(selectedFiles);
      for (const file of selectedFiles) {
        data.append("images", file);
      }
      // data.append('files', selectedFiles);

      // data.append('storePath', path);
      //   data.append("bucket", bucketName);

      if (localStorage.getItem("customerId")) {
        data.append("customerId", localStorage.getItem("customerId"));
      }

      // console.log('data', data, data.getAll('files'));
      const headers = {
        // 'Content-Type': 'application/json',
        Authorization: "Bearer " + token,
      };

      const result = await axios.request({
        method: "post",
        url: url + "/image-combine",
        data: data,
        headers: headers,
        onUploadProgress: (p) => {
          // console.log('onUploadProgress', (p.loaded/p.total*100).toFixed(0), p);

          let message = `Uploading... ${((p.loaded / p.total) * 100).toFixed(
            0
          )}%`;

          if (p.loaded / p.total === 1) {
            message = "Uploaded, please wait until storing files finish...";
          }

          //   dispatch('SHOW_NOTIFICATION', {
          //     status: '',
          //     title: message,
          //     message: '',
          //   });
        },
      });

      console.log(result);

      if (result.status === 200 && result.data.data) {
        //   setCombinedBuffer(result.data.data);
        //   const base64String = btoa(String.fromCharCode(...new Uint8Array(result.data.data.data)));
        //   console.log(base64String)
        setCombinedB64String(result.data.data.imageB64String);
      }

      dispatch('SET_GLOADING', false);
    } catch (err) {
      console.log(err);
      dispatch('SET_GLOADING', false);
      throw err;
    }
  };

  const getImgDimension = (event, inputNum) => {
    // console.log(event, event.target.naturalHeight, event.target.naturalWidth);

    // console.log(event, event.target.naturalHeight, event.target.naturalWidth);
    if (inputNum === 1) {
      if (
        event.target.naturalHeight !== fileDimension.height ||
        event.target.naturalWidth !== fileDimension.width
      ) {
        setFileDimension({
          height: event.target.naturalHeight,
          width: event.target.naturalWidth,
        });
      }
    } else {
      if (
        event.target.naturalHeight !== fileDimension2.height ||
        event.target.naturalWidth !== fileDimension2.width
      ) {
        setFileDimension2({
          height: event.target.naturalHeight,
          width: event.target.naturalWidth,
        });
      }
    }
  };


  let sameTypeErrorMessage = "";
  let fileSizeLimitMessage = "";
  let fileScaleLimitMessage = "";

  let isFileBig = false;
  let isFile2Big = false;

  if (inputFile && inputFile2 && inputFile.type !== inputFile2.type) {
    sameTypeErrorMessage = <span>
      {t('combiner12', 'Image type is different. (accept jpeg or png file. Files should be same type.)')}
      <br/>
      It is possible to convert file format 
      {' '}
      in Image Convert.
      <br/>
      {/* <button className="btnBase"
        disabled={isLoading}
        onClick={() => {
          setShowImageConvert(true)
        }}
      >
        Image Convert
      </button> */}
    </span>
  }

  if (inputFile && inputFile.size > 5*10**6) {
    fileSizeLimitMessage = <span>
      {t('combiner13', 'File size should be less than 5 MB.')}
      <br/>
      It is possible to resize file 
      {' '}
      in Image Resizer.
    </span>
  }

  if (inputFile2 && inputFile2.size > 5*10**6) {
    fileSizeLimitMessage = <span>
      {t('combiner13', 'File size should be less than 5 MB.')}
      <br/>
      It is possible to resize file 
      {' '}
      in Image Resizer.
    </span>
  }

  if (fileDimension && (fileDimension.height > 2000 || fileDimension.width > 2000)) {
    isFileBig = true; 

    fileScaleLimitMessage = <span>
      {t('combiner14', 'Width and Height should be less than 2000px.')}
      <br/>
      It is possible to resize file 
      {' '}
      in Image Resizer.
    </span>
  }

  if (fileDimension2 && (fileDimension2.height > 2000 || fileDimension2.width > 2000)) {
    isFile2Big = true;

    fileScaleLimitMessage = <span>
      {t('combiner14', 'Width and Height should be less than 2000px.')}
      <br/>
      It is possible to resize file 
      {' '}
      in Image Resizer.
    </span>
  }

  return (
    <Fragment>
      <section className="combinerSectoin">
        <div className="combinerInfoMark">
          <span
            onClick={() => {
              setShowCombinerInfo(!showCombinerInfo);
            }}
          >
            {t('combiner01', 'info')} {marks.infoCircleMark}
          </span>
        </div>
        {showCombinerInfo && (
          <div>
            <CombinerInfo />
          </div>
        )}
      </section>
      <section className="combinerSectoin">
        <div className="combinerInputButtons">
          <button
            //   disabled={gLoading}
            className="btnBase"
            onClick={(event) => {
              handleInputClick(event, 1);
            }}
          >
            {t('combiner02', 'Select image')} 1
          </button>
          <button
            //   disabled={gLoading}
            className="btnBase"
            onClick={(event) => {
              handleInputClick(event, 2);
            }}
          >
            {t('combiner02', 'Select image')} 2
          </button>
          <input
            id="fileinput"
            type="file"
            ref={hiddenFileInput}
            style={{ display: "none" }}
            // onChange={imageInputHandler}
            onChange={(event) => {
              blobProcessHandler(event, 1);
            }}
            accept="image/png,image/jpeg"
          />
          <input
            id="fileinput2"
            type="file"
            ref={hiddenFileInput2}
            style={{ display: "none" }}
            // onChange={imageInputHandler}
            onChange={(event) => {
              blobProcessHandler(event, 2);
            }}
            accept="image/png,image/jpeg"
          />
          <span> or </span>
          <button className="btnBase"
            onClick={() => {
              setShowFileSelectModal(true);
            }}
          >
            {marks.cloudDownloadMark} 
            {' '}
            {t('combiner15', 'Use uploaded files')}
          </button>
        </div>
      </section>

      <section className="combinerSectoin">
        <div className="combinerInputImages">
          <div>
            {inputFile && (
              <div>
                <div>{t('combiner03', 'Image')} 1</div>
                <img
                  className="combinerInputImage"
                  // style={{ maxHeight: "100px" }}
                  title={decodeURI(inputFile.name)}
                  src={URL.createObjectURL(inputFile)}
                  alt="for resize"
                  onClick={() => {}}
                  onLoad={(event) => {
                    getImgDimension(event, 1);
                  }}
                />
              </div>
            )}
            {fileDimension.height > 0 && (
              <div className="combinerImageDeminsions">
                (W: {fileDimension.width}, H: {fileDimension.height})
              </div>
            )}
              {inputFile && (
              <div className="combinerImageDeminsions">
                {numeral(inputFile.size).format('0.0 b')} ({inputFile.type.split('/')[1]})
              </div>
            )}

            {(isFileBig || sameTypeErrorMessage) && (
              <button className="btnBase"
                style={{fontSize: "0.9rem"}}
                disabled={isLoading}
                onClick={() => {
                  if (!isLoading) {
                    setEditSelectedNo(1);
                  }
                }}
              >
                resize or change format
              </button>
            )}
            
          </div>
          <div>
            {inputFile2 && (
              <div>
                <div>{t('combiner03', 'Image')} 2</div>
                <img
                  className="combinerInputImage"
                  // style={{ maxHeight: "100px" }}
                  title={decodeURI(inputFile2.name)}
                  src={URL.createObjectURL(inputFile2)}
                  alt="for resize"
                  onClick={() => {}}
                  onLoad={(event) => {
                    getImgDimension(event, 2);
                  }}
                />
              </div>
            )}
            {fileDimension2.height > 0 && (
              <div className="combinerImageDeminsions">
                (W: {fileDimension2.width}, H: {fileDimension2.height})
              </div>
            )}
            {inputFile2 && (
              <div className="combinerImageDeminsions">
                {numeral(inputFile2.size).format('0.0 b')} ({inputFile2.type.split('/')[1]})
              </div>
            )}

          {(isFile2Big || sameTypeErrorMessage) && (
            <button className="btnBase"
              style={{fontSize: "0.9rem"}}
              disabled={isLoading}
              onClick={() => {
                if (!isLoading) {
                  setEditSelectedNo(2);
                }
              }}
            >
              resize or change format
            </button>
          )}
    

          </div>
        </div>

        {sameTypeErrorMessage && (
          <div className="combinerSectoin">{sameTypeErrorMessage}</div>
        )}
        {fileSizeLimitMessage && (
          <div className="combinerSectoin">{fileSizeLimitMessage}</div>
        )}
        {fileScaleLimitMessage && (
          <div className="combinerSectoin">{fileScaleLimitMessage}</div>
        )}
      </section>

      <div className="combinerSectoin">
        <button
          className="btnBase"
          style={{ padding: "1rem", fontWeight: "bold", fontSize: "1.25rem"}}
          disabled={!inputFile || !inputFile2 || sameTypeErrorMessage || fileSizeLimitMessage || fileScaleLimitMessage}
          onClick={() => {
            getCombinedHandler(
              BACKEND_URL,
              localStorage.getItem("token"),
              [inputFile, inputFile2]
            );
          }}
        >
          {t('combiner04', 'Combine images')}
        </button>
      </div>

      {showFileSelectModal && (
        <div>
          <Backdrop 
            onCancel={() => { 
              if (!isLoading) {
                setShowFileSelectModal(false);
              }
            }}
          />
          <Modal
            onClose={() => { 
              if (!isLoading) {
                setShowFileSelectModal(false);
              }
            }}
            hideButtons={true}
          >
            <CombinerFileSelectContents 
              selectedFileKind={selectedFileKind}
              setSelectedFileKind={setSelectedFileKind}
              setInputFile={setInputFile}
              setInputFile2={setInputFile2}
              setShowFileSelectModal={setShowFileSelectModal}
              isLoading={isLoading}
            />
          </Modal>
        </div>
      )}


      {showImageConvert && (
          <div>
            <Backdrop 
                onCancel={() => { 
                  if (!isLoading) {
                    setShowImageConvert(false); 
                  }
                }}
              />
              <Modal
                onClose={() => { 
                  if (!isLoading) {
                    setShowImageConvert(false); 
                  }
                }}
              >
              <ImageConvert 
                propConvertFiles={inputFiles}
              />
          </Modal>
        </div>
              
      )}

      {editSelectedNo === 1 && (
        <CombinerFileEdit 
          editFile={inputFile}
          editSelectedNo={editSelectedNo}
          setEditSelectedNo={setEditSelectedNo}
          setInputFile={setInputFile}
          setInputFile2={setInputFile2}
        />
      )}
      {editSelectedNo === 2 && (
        <CombinerFileEdit 
          editFile={inputFile2}
          editSelectedNo={editSelectedNo}
          setEditSelectedNo={setEditSelectedNo}
          setInputFile={setInputFile}
          setInputFile2={setInputFile2}
        />
      )}
    </Fragment>
  );
}
