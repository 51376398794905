import { Fragment, useState, useEffect, useRef, } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Resizer from "react-image-file-resizer";
import download from 'downloadjs';
import numeral from 'numeral'
// import _ from 'lodash';

import ImageFileUpload from '../ImageFileUpload/ImageFileUpload';
import ResizerResizeList from './ResizerResizeList';
import ResizerUpload from './ResizerUpload';

import { useStore } from '../../hook-store/store';

import { marks } from '../../utils/marks';
import './ResizerResize.css'
// import classes from './Footer.module.css';

// import { logoutHandler } from '../../utils/user';
import { loginPageLink, signupPageLink } from '../../App';

export default function ResizerResize(props) {
  // console.log('Layout.js-props', props);

  const [t] = useTranslation('translation');


  const [store, dispatch] = useStore();
  const { 
    resizerFiles,
    resizerSelected,
    resizedFiles,
  } = store.imageResizerStore;
  const { isAuth } = store.uiStore;

  const [fileDimension, setFileDimension] = useState({ height: 0, width: 0 });
  
  const [resizeHeight, setResizeHeight] = useState(0);
  const [resizeWidth, setResizeWidth] = useState(0);
  // const [b64Img, setB64Img] = useState();
  const [resizeBlob, setResizeBlob] = useState();

  const [resizeMessage, setResizeMessage] = useState('');
  const [showInputUploadModal, setShowInputUploadModal] = useState(false);

  const inputFilesForUpload = resizerFiles.map(element => {
    return element.file;
  });

  let tooBigResize = false;

  // if (resizeHeight >= fileDimension.height || 
  //       resizeWidth >= fileDimension.width 
  // ) {
  //     tooBigResize = true;
  // }



  useEffect(() => {
    console.log('resizedFiles', resizedFiles);
    // if (resizerFiles.length === 0) {
    //   dispatch('SET_RESIZERSELECTED', null);
    // }

    const newResizedList = [];

    for (const rFile of resizedFiles) {
      const existFile = resizerFiles.find(element => {
        return element.id === rFile.id;
      });

      if (existFile) {
        newResizedList.push(existFile);
      } else {
        //// deselect when not exist in new resizerFiles;
        dispatch('SET_RESIZERSELECTED', null);
      }
    }

    dispatch('SET_RESIZEDFILES', newResizedList);
     
  },[resizerFiles])


  useEffect(() => {
    setResizeHeight(0);
    setResizeWidth(0);

    setResizeMessage('');
  },[resizerSelected]);



  const setResizeSizeHandler = (event, dimension) => {
    const inputNum = Number(event.target.value);

    if (!event.target.value) {
      setResizeHeight(0);
      setResizeWidth(0);
    }

    if (fileDimension && inputNum > 0) {
      if (dimension === 'height') {
          setResizeHeight( Number(inputNum.toFixed()) );
        }
        
        const resizeWidth = inputNum * (fileDimension.width / fileDimension.height);
        setResizeWidth( Number(resizeWidth.toFixed()) );
        
      }
  
      if (dimension === 'width') {
        setResizeWidth( Number(inputNum.toFixed()) );
  
        const resizeHeight = inputNum * (fileDimension.height / fileDimension.width);
        setResizeHeight( Number(resizeHeight.toFixed()) );
      }
  };


  const resizeFile = (file, outPut, ext) => {
    const outputType = outPut ? outPut : 'base64';
    const extName = ext ? ext : "JPEG";
    // console.log(extName)

    return new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        resizeWidth,
        resizeHeight,
        extName,
        100,
        0,
        (uri) => {
          resolve(uri);
        },
        outputType,
        resizeWidth,
        resizeHeight,
      );
  });
  }


  const onResize = async (file) => {
    try {
      // console.log(file);
      const ext = file.type.split('/')[1];
      // console.log(ext);

      const image = await resizeFile(file, 'base64', ext);
      // console.log(image);
      // setB64Img(image);

      const resBlob = await resizeFile(file, 'blob', ext);
      setResizeBlob(resBlob);

      const resFile = await resizeFile(file, 'file', ext);
      console.log(resFile);

      const splitList = resFile.name.split('.');
      const newExt = splitList.pop();
      // const newName = splitList.join('.') + `-${Date.now()}-resize.` + newExt;
      const newName = `resize-${Date.now()}.` + newExt;

      const newFile = new File([resFile], newName, {type: resFile.type});
      
      const imageBitmap = await createImageBitmap(newFile);

      const resizedList = resizedFiles;

      const filtedList = resizedList.filter(element => {
        return element.id !== resizerSelected.id
      });

      const addedList = filtedList.concat({
        id: resizerSelected.id,
        file: newFile,
        blob: resBlob,
        targetDimension: {
          height: resizeHeight,
          width: resizeWidth,
        },
        imageBitmap: imageBitmap,
      });

      dispatch('SET_RESIZEDFILES', addedList);
      // console.log(resFile, ext);

      setResizeMessage(
        `resize success, file size ${numeral(newFile.size).format('0.0 b')}, height: ${imageBitmap.height}, width: ${imageBitmap.width} `);

      setTimeout(() => {
        setResizeMessage('');
      },1000*5);

    } catch (err) {
      console.log(err);
    }
  };



  const getImgDimension = (event) => {
    // console.log(event, event.target.naturalHeight, event.target.naturalWidth);
    
    if (event.target.naturalHeight !== fileDimension.height ||
        event.target.naturalWidth !== fileDimension.width
    ) {

        // console.log(event, event.target.naturalHeight, event.target.naturalWidth);

        setFileDimension({
          height: event.target.naturalHeight,
          width: event.target.naturalWidth,
        });
      }
  }



  // async function toDataURL(url) {
  //   // const blob = await fetch(url).then(res => res.blob());
  //   const blob = fileBlob
  //   return URL.createObjectURL(blob);
  // }

  // async function download() {
  //   const a = document.createElement("a");
  //   a.href = await toDataURL("https://cdn1.iconfinder.com/data/icons/ninja-things-1/1772/ninja-simple-512.png");
  //   a.download = "myImage.png";
  //   document.body.appendChild(a);
  //   a.click();
  //   document.body.removeChild(a);
  // }

  async function toDataURLFromBlob(blob) {
    return URL.createObjectURL(blob);
  }

  async function downloadBlob(blob, file) {
    const a = document.createElement("a");
    a.href = await toDataURLFromBlob(blob);
    a.download = "download.png";
    
    const fileName = file.name;
    const separateList = fileName.split('.');
    const ext = separateList.pop();
    const noExt = separateList.join('.');

    if (noExt && ext) {
      // a.download = noExt + '-resize.' + ext
      a.download = `resize-${Date.now()}.${ext}`
    }

    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }


  const filesDownloadHandler = (resizedFiles) => {
    
    for (const resizedFile of resizedFiles) {
      download(
        resizedFile.file, 
        resizedFile.file.name, 
        resizedFile.file.type
      );
    }
  }

  let resizerResizeBody;

  if (resizerSelected) {
    resizerResizeBody = (
      <div>
        <div>
          <div>
              {/* {resizerSelected.file.name} */}
              {decodeURI(resizerSelected.file.name)}
            </div>
            <div>
              {t('resizer07', 'height')} <strong>{fileDimension.height}px</strong>
              {', '}
              {t('resizer08', 'width')} <strong>{fileDimension.width}px</strong>
          </div>
          <div className='resizerResizeControl'>
            <img 
              id='image'
              className='resizerResizePreview'
              src={URL.createObjectURL(resizerSelected.file)}
              alt="input file" 
              onLoad={getImgDimension}
            />
            <div className='resizerResizeInputs'>
              <div>
                <label>{t('resizer09', 'resize height')} (px)</label>
                <br/>
                <input 
                  className='inputBase'
                  style={{maxWidth: "10rem"}}
                  type="text"
                  // autoFocus
                  onChange={(event) => { setResizeSizeHandler(event, 'height') }}
                  value={resizeHeight <= 0 ? '' : resizeHeight}
                />
              </div>
              <div>
                <label>{t('resizer10', 'resize width')} (px)</label>
                <br/>
                <input 
                  className='inputBase'
                  style={{maxWidth: "10rem"}}
                  type="text"
                  onChange={(event) => { setResizeSizeHandler(event, 'width') }}
                  value={resizeWidth <= 0 ? '' : resizeWidth}
                />
              </div>
              {tooBigResize && (
                <div>
                  <strong>{t('resizer11', 'too big size')}</strong>
                </div>
              )}
              
            </div>
          </div>
        </div>

        <div className='resizerResizeResizeButton'>
          <div>
            <button className='btnBase'
              disabled={resizeHeight <= 0 || resizeWidth <= 0 || tooBigResize}
              onClick={() => { 
                onResize(resizerSelected.file); 
              }}
            >
              {t('resizer12', 'Resize image')}
            </button>
          </div>

          {resizeMessage && (
            <div>
              <strong>{resizeMessage}</strong>
            </div>
          )}
        </div>

        <div>
          <ResizerResizeList />
        </div>
        
      </div>
    );
  }





  return (
    <Fragment>
      <div>{resizerResizeBody}</div>
      
      <div className='resizerResizeFileControllButtons'>
          <div>
            <button className='btnBase'
              disabled={resizedFiles.length === 0}
              onClick={() => { filesDownloadHandler(resizedFiles) }}
            >
              {marks.downloadMark} {t('resizer13', 'Download resized images')}
              {resizedFiles.length > 0 && (
                <span> ({resizedFiles.length})</span>
              )}
            </button>
          </div>
          <div>
            <ResizerUpload />
          </div>
      </div>

      <div className='imageConvertElement'>
        <button className='btnBase'
          disabled={inputFilesForUpload.length === 0}
          onClick={() => {
            setShowInputUploadModal(true);
          }}
        >
          {marks.uploadMark}{' '}
          {t('resizer18', 'Upload input files')}
          {' '}({inputFilesForUpload.length})
        </button>
    
        {showInputUploadModal && (
          <ImageFileUpload 
            files={inputFilesForUpload}
            onClose={() => { 
              setShowInputUploadModal(false) 
            }}
            uploadApp={'imageResizer'}
          />
        )}
      </div>

      {!isAuth && (
        <div>
          <p>
            {t('aboutPage.09', 'Login to upload and store files, or create account')}
          </p>

          <div className="resizerResizeLoginButtons">
            <a href={loginPageLink}>
              <button className="btnBase">
                {marks.signInMrak} {t('aboutPage.10', 'Login')}
              </button>
            </a>
            <span> {t('aboutPage.11', 'or')} </span>
            <a href={signupPageLink}>
              <button className="btnBase">
                {marks.userPlusMrak} {t('aboutPage.12', 'Signup')}
              </button>
            </a>
          </div>
        </div>
      )}
     
      {/* <div>resized-flies-num: {resizedFiles.length}</div> */}
    </Fragment>
  );
}

