import { Fragment, useState, useEffect, useRef, } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import numeral from 'numeral';

import ImageFileNameChange from '../ImageFileNameChange/ImageFileNameChange';
import { useStore } from '../../hook-store/store';


import { marks } from '../../utils/marks';

import './ResizerResize.css'
// import classes from './Footer.module.css';

export default function ResizerResizeList(props) {
  // console.log('Layout.js-props', props);

  const [t] = useTranslation('translation');

  const navigate = useNavigate();

  const [store, dispatch] = useStore();
  const { 
    resizedFiles,
  } = store.imageResizerStore;
  // const { bucketName, allBucketObjects } = store.bucketObjectStore;
  const { gLoading, isAuth } = store.uiStore;

  const [showResultList, setShowResultList] = useState(false);
  const [selectedConvertedId, setSelectedConvertedId] = useState('');
  const [showNameChangeModal, setShowNameChangeModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);



  useEffect(() => {
    if (gLoading) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  },[gLoading]);


  const changeResultFileNameChangeHandler = (changedFile) => {
    console.log(changedFile, selectedConvertedId);

    const updatedFiles = resizedFiles.map(element => {
      console.log(element)
      if (element.id === selectedConvertedId) {
        return {
          ...element,
          file: changedFile,
        }
      } else {
        return element;
      }
    });

    // console.log(updatedFiles);

    dispatch('SET_RESIZEDFILES', updatedFiles);

    dispatch('SHOW_NOTIFICATION', {
      status: '',
      title: 'name change success',
      message: '',
    });

    setTimeout(() => {
      dispatch('CLEAR_NOTIFICATION');
    },1000 * 3);
  };



  
  let imageResizerResizeListBody;


  if (showResultList && resizedFiles.length > 0) {
    imageResizerResizeListBody = (
      <div>
        <section className='imageConvertParamElements'>
          <ul>
            {resizedFiles.map(resizedObj => {
              return (
                <li 
                  className='imageConvertElement'
                  key={resizedObj.id} 
                >
                  <div className='imageConvertConvertListItem'>
                    <img 
                      className='convertResizePreview'
                      style={{maxHeight: "150px", maxWidth:"150px"}}
                      src={URL.createObjectURL(resizedObj.file)}
                      alt="convert selected file" 
                      // onLoad={getImgDimension}
                    />
                    <div className='imageConvertElement'>
                      height {resizedObj.imageBitmap.height}px
                      {', '}
                      width {resizedObj.imageBitmap.width}px
                    </div>
                    <span>
                      <div className='imageConvertElement'>
                        {resizedObj.file.name} ({numeral(resizedObj.file.size).format('0.0 b')})
                      </div>
                      <div className='imageConvertElement'>
                        <div className="">
                          <button
                            className="btnBase"
                            onClick={() => {
                              setSelectedConvertedId(resizedObj.id);
                              setShowNameChangeModal(true);
                            }}
                          >
                            {t('fileAction.06', 'Change Name')}
                          </button>
                        </div>
                        {showNameChangeModal && selectedConvertedId === resizedObj.id && (
                          <ImageFileNameChange 
                            // currentRenderFile={resizedObj}
                            currentRenderFile={resizedObj.file}
                            // onButtonClick={() => { setSelectedConvertedId(resizedObj.id); }}
                            createUploadFile={changeResultFileNameChangeHandler}
                            onClose={() => { 
                              setShowNameChangeModal(false); 
                              setSelectedConvertedId('');
                            }}
                          />
                        )}
                      </div>
                    </span>
                  </div>
                </li>
              )
            })}
          </ul>
        </section>
      </div>
    )
  }
 




  return (
    <Fragment>
      {resizedFiles.length > 0 && (
        <div className='imageConvertElement'>
          <span
            onClick={() => {
              setShowResultList(!showResultList);
            }}
          >
            {t('resizer19', 'display resized files')} {marks.triangleDown}
          </span>
        </div>
      )}
      <div>
        {imageResizerResizeListBody}
      </div>
    </Fragment>
  );
}

