import React from 'react';
import { Fragment, useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';

import {
  ArrowUpTrayIcon,
} from '@heroicons/react/24/outline';

import Button from '../shared/Button.tsx';
// import { useRecoilState } from 'recoil'
// import {
//   fileState,
// } from '../../store/Atoms.tsx'
// import { srcToFile } from '../../utils.ts';

// import Backdrop from '../../../Backdrop/Backdrop';
// import Modal from '../../../Modal/Modal';
// import FileUploadModalContent from './FileUploadModalContent';
// import DownLoadFile from './DownloadFile';
// import Folder from '../../BucketComponents/Folder/Folder';
// import Loader from '../../Loader/Loader';
// import UploadEditFileModalContent from './UplaodEditFileModalContent';


import { useStore } from '../../../../hook-store/store';

import * as uploadUtils from '../../../../utils/bucket/bucket-upload-util';

import { BACKEND_URL, loginPageLink, signupPageLink } from '../../../../App';

import { marks } from '../../../../utils/marks';
// import './UploadEditFile.css';
// import "./tui-image-editor.css";
// import "./FileControl.css";

export default function FileUploadButton(props) {
  const { 
    renders,
    // getCurrentRender,
    // loadedImageName,
    // undoStackLength,
  } = props;

  // const [file, setFile] = useRecoilState(fileState);

  // console.log('renders, file in FileuploadButton.js', renders, file);

  const [t] = useTranslation('translation');
  // console.log('UploadEditFile-props', props);

  const [store, dispatch] = useStore();
  // const { 
  //   bucketName, 
  //   topLevels, 
  //   totalSizeGB, 
  //   limitGB,
  //   selectedPrefix,
  // } = store.bucketObjectStore;
  const { gLoading, isAuth, isAllApis } = store.uiStore;


  // const [imageData, setImageData] = useState();
  // const [selectedFile, setSelectedFile] = useState();
  
  // const [filePath, setFilePath] = useState('');
  // const [fileName, setFileName] = useState('');
  // const [showUploadModal, setShowUploadModal] = useState(false);
  // const [outputFormat, setOutputFormat] = useState('png');
  // const [editFile, setEditFile] = useState();

  const [isLoading, setIsLoading] = useState();


  useEffect(() => {
    if (gLoading) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  },[gLoading]);



  const showUploadMoadlHandler = (value) => {
    // setShowUploadModal(value);
    dispatch('SET_SHOW_LAMAUPLOADMODAL', value);
  }

 

  let fileUploadButtonBody;

  fileUploadButtonBody = (
    // <button
    //   disabled={!renders.length}
    //   onClick={() => {
    //     if (!isLoading) {
    //       showUploadMoadlHandler(true);
    //     }
    //     // cleanImageUploadHandler(''); 
    //   }}
    // >
    //   <ArrowUpTrayIcon />
    // </button>
    <Button
      toolTip="Upload Image"
      icon={<ArrowUpTrayIcon />}
      disabled={!renders.length}
      onClick={() => {
        if (!isLoading) {
          showUploadMoadlHandler(true);
        }
        // cleanImageUploadHandler(''); 
      }}
    />
  );


  return (
    <Fragment>
      <div>{fileUploadButtonBody}</div>
    </Fragment>
  );
}

