// const React = window.React = require('react');
import React, { Fragment, useState } from "react";
// import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
// import Img from "react-cool-img";

import { useStore } from "../../hook-store/store";
import { drawDreamerLink } from "../../App";

import { marks } from "../../utils/marks";
import classes from "./AboutDrawDreamer.module.css";

// import DrawDreamSketch from "../../images/drawDreamer/a-cup-of-coffee-on-dish-draw.jpeg";
// import DrawDreamSample from "../../images/drawDreamer/a-cup-of-coffee-on-dish-x10.jpeg";
import DrawDreamSketch2 from "../../images/drawDreamer/panda-jungle-sketch-h150.jpeg";
import DrawDreamSample2 from "../../images/drawDreamer/pand-jungle-output-h150.jpeg";
import DrawDreamSketch3 from "../../images/drawDreamer/rg-apple-sketch-input-h150.jpeg";
import DrawDreamSample3 from "../../images/drawDreamer/rg-apple-output-h150.jpeg";
import DrawDreamSketch4 from "../../images/drawDreamer/two-coffee-sketch-h150.jpeg";
import DrawDreamSample4 from "../../images/drawDreamer/two-coffee-output-h150.jpeg";
import DrawDreamSketch5 from "../../images/drawDreamer/astronaut-in-woods-sketch-h150.jpeg";
import DrawDreamSample5 from "../../images/drawDreamer/astronaut-in-woods-output-150.jpeg";
import PromptGenSample1 from "../../images/drawDreamer/a-bed-photographic-h200.jpeg";
import PromptGenSample2 from "../../images/drawDreamer/a-bed-fantasy-art-h200.jpeg";
import ImageToImageInput from "../../images/drawDreamer/image-to-image/woman-turban-input-h200.png";
import ImageToImageOutput1 from "../../images/drawDreamer/image-to-image/woman-turban-output-h200.jpeg";
import ImageToImageOutput2 from "../../images/drawDreamer/image-to-image/woman-turban-man-output-h200.jpeg";
import ImageRefinerInput from "../../images/drawDreamer/image-refiner/room-bed-1024-h128.jpg";
import ImageRefinerOutput from "../../images/drawDreamer/image-refiner/bed-h128-refine.jpeg";
// import ImageRefinerInput2 from "../../images/drawDreamer/image-refiner/man-h128.png";
// import ImageRefinerOutput2 from "../../images/drawDreamer/image-refiner/man-h128-refine.jpeg";
import DrawIpaSketch from "../../images/drawDreamer/draw-ipa/draw-ipa-sketch-h200.jpeg";
import DrawIpaImage from "../../images/drawDreamer/draw-ipa/draw-ipa-a-woman-in-armor-h200.png";
import DrawIpaResult from "../../images/drawDreamer/draw-ipa/draw-ipa-result-h200.jpeg";
import DrawIpaSketch2 from "../../images/drawDreamer/draw-ipa/draw-ipa2-sketch2-h200.png";
import DrawIpaImage2 from "../../images/drawDreamer/draw-ipa/draw-ipa2--h200.png";
import DrawIpaResult2 from "../../images/drawDreamer/draw-ipa/draw-ipa2-result2.jpeg";
import ImageToImageIpaInput from "../../images/drawDreamer/image-to-image-ipa/i2i-ipa-input-h200.png";
import ImageToImageIpaImage from "../../images/drawDreamer/image-to-image-ipa/i2i-ipa-image-h200.png";
import ImageToImageIpaResult from "../../images/drawDreamer/image-to-image-ipa/i2i-ipa-result-h200.jpeg";
// import ImageToImageIpaInput2 from "../../images/drawDreamer/image-to-image-ipa/i2i-ipa2-input-h200.jpg";
// import ImageToImageIpaImage2 from "../../images/drawDreamer/image-to-image-ipa/i2i-ipa2-image-h200.png";
// import ImageToImageIpaResult2 from "../../images/drawDreamer/image-to-image-ipa/i2i-ipa2-result-h200.jpeg";
import ImageStylizerInput from "../../images/drawDreamer/image-stylizer/statue-image-h200.png";
import ImageStylizerStyle from "../../images/drawDreamer/image-stylizer/gogh-3-h150.jpg";
import ImageStylizerResult from "../../images/drawDreamer/image-stylizer/img-st-is[0.7,1.0]-gogh3-h150.jpg";
import ImageStylizerInput2 from "../../images/drawDreamer/image-stylizer/multi-ipa-face-h150.jpg";
import ImageStylizerStyle21 from "../../images/drawDreamer/image-stylizer/multi-ipa-style-0-h150.jpg";
import ImageStylizerStyle22 from "../../images/drawDreamer/image-stylizer/multi-ipa-style-5-h150.jpg";
import ImageStylizerStyle23 from "../../images/drawDreamer/image-stylizer/multi-ipa-style-6-h150.jpg";
import ImageStylizerResult2 from "../../images/drawDreamer/image-stylizer/img-is[0.8,0.5]-ani056-h150.jpg";

import AnimeImageInput from "../../images/drawDreamer/anime-image/girl-h150.png";
import AnimeImageOutput from "../../images/drawDreamer/anime-image/girl-anime-h150.jpg";
import AnimeImageInput2 from "../../images/drawDreamer/anime-image/armor-woman-h150.png";
import AnimeImageOutput2 from "../../images/drawDreamer/anime-image/armor-woman-anime-h150.jpg";
import AnimeImageOutput22 from "../../images/drawDreamer/anime-image/armor-woman-anime-book-h150.jpg";
import AnimeImageOutput23 from "../../images/drawDreamer/anime-image/armor-woman-anime-sunglass-h150.jpg";

import StyleFusionInput1 from "../../images/drawDreamer/style-fusion/house-150.jpeg";
import StyleFusionStyle1 from "../../images/drawDreamer/style-fusion/st-05-150.jpeg";
import StyleFusionOutput1 from "../../images/drawDreamer/style-fusion/style-fusion-house-2-150.jpg";
import StyleFusionInput2 from "../../images/drawDreamer/style-fusion/face-150.jpeg";
import StyleFusionStyle2 from "../../images/drawDreamer/style-fusion/st-02-150.jpeg";
import StyleFusionOutput2 from "../../images/drawDreamer/style-fusion/style-fusion-man-150.jpg";

// import GuideImage from "../../images/drawDreamer/bed-guide.jpeg";
// import GuideDreamSample from "../../images/drawDreamer/a-bed-in-room-384x6.jpeg";

const AboutDrawDreamer = (props) => {
  const [t] = useTranslation("translation");

  const [store, dispatch] = useStore();
  const { isAuth } = store.uiStore;

  const [showMoreExample, setShowMoreExample] = useState(false);

  let aboutDrawDreamerBody;

  aboutDrawDreamerBody = (
    <div>
      <p>
        Kura Draw Dreamer
        {' '}
        {t('aboutPage.20', 'is a simple whiteboard to generate images. User can generate full color image from drawed monotone sketch image.')}
      </p>
      <br/>
      <p>
      {t('aboutPage.21', 'Advanced AI technologies enable to generate images by combining drawed image with prompt description.')}
      <br/>
      {/* {t('aboutPage.22', 'Instead of drawing image, it is also possible to create guide image from image file for image generation.')} */}
      </p>
      <br/>

      <p>
        <strong>
        {t('aboutPage.23', 'Example of image generation')}
        </strong>
      </p>
      <br/>
      <p>
        <img
          style={{ height: "150px", maxWidth: "100%" }}
          src={DrawDreamSketch5}
        />
        <div>Sketch image</div>
      </p>
      <br/>
      <p>
        <img
          style={{ height: "150px", width: "150px" }}
          src={DrawDreamSample5}
        />
        <div>
          Generated image from sketch image with prompt &#34;an astronaut walking in the forest&#34;
        </div>
      </p>
      <br/>

      <div>
        <p>
          {t('aboutDrawDreamer01', 'It is possible to use image with sketch and text prompt.')}
          {' '}(Image prompt)
        </p>
        <br/>
        <div className={classes.aboutDrawDreamerImages}>
          <p>
            <img
              style={{ height: "150px", maxWidth: "100%" }}
              src={DrawIpaSketch}
            />
            <div>Sketch image</div>
          </p>
          <br/>
          <p>
            <img
              style={{ height: "150px", width: "150px" }}
              src={DrawIpaImage}
            />
            <div>
              Image prompt
            </div>
          </p>
        </div>
        <br/>
        <p>
          <img
            style={{ height: "150px", width: "150px" }}
            src={DrawIpaResult}
          />
          <div>
            a woman in armor, raising a hand
          </div>
        </p>
      </div>
      <br/>

      <div>
        <span onClick={() => { setShowMoreExample(!showMoreExample) }}>
          {t('aboutPage.42', 'Display more examples')} {marks.triangleDown}
        </span>
      </div>
      {showMoreExample && (
        <div>
          <div>
            <div>
              <img className={classes.aboutDrawDreamerMoreImage}
                src={DrawDreamSketch2}
              />
              <div>Sketch image</div>
            </div>
            <br/>

            <div>
              <img className={classes.aboutDrawDreamerMoreImage}
                src={DrawDreamSample2}
              />
              <div>
                Generated image from sketch image with prompt &#34;a panda sitting in the jungle&#34;
              </div>
            </div>
          </div>
          <br/>

          <div>
            <div>
              <img className={classes.aboutDrawDreamerMoreImage}
                src={DrawDreamSketch3}
              />
              <div>Sketch image</div>
            </div>
            <br/>

            <div>
              <img className={classes.aboutDrawDreamerMoreImage}
                src={DrawDreamSample3}
              />
              <div>
                Generated image from sketch image with prompt &#34;red and  green apples on table&#34;
              </div>
            </div>
          </div>
          <br/>

          <div>
            <div>
              <img className={classes.aboutDrawDreamerMoreImage}
                src={DrawDreamSketch4}
              />
              <div>Sketch image</div>
            </div>
            <br/>

            <div>
              <img className={classes.aboutDrawDreamerMoreImage}
                src={DrawDreamSample4}
              />
              <div>
                Generated image from sketch image with prompt &#34;two cups of coffee on dish on table&#34;
              </div>
            </div>
          </div>
          <br/>

          <div>
            <div className={classes.aboutDrawDreamerImages}>
              <p>
                <img
                  style={{ height: "150px", maxWidth: "100%" }}
                  src={DrawIpaSketch2}
                />
                <div>Sketch image</div>
              </p>
              <br/>
              <p>
                <img
                  style={{ height: "150px", width: "150px" }}
                  src={DrawIpaImage2}
                />
                <div>
                  Image prompt
                </div>
              </p>
            </div>
            <br/>
            <p>
              <img
                style={{ height: "150px", width: "150px" }}
                src={DrawIpaResult2}
              />
              <div>
                portrait of a young man
              </div>
            </p>
          </div>
        </div>
      )}
      <br/>
      <br/>

      <div>
        <strong>
          Text Prompt Image Generation
        </strong>
      </div>

      <p>
        In Kura Draw Dreamer, 
        {' '}
        {t('aboutPage.36', 'it is possible to generate image from text prompt without drawing sketch as well.')}
        <br/>
        {t('aboutPage.37', 'By selecting image style, different style of images are generated from same text.')}
      </p>
      <br/>

      <div>
        Example of Text Prompt Image Generation
      </div>
      <br/>
      <br/>

      <div>
        <img className={classes.aboutDrawDreamerMoreImage}
          style={{ height: "150px", maxWidth: "100%" }}
            src={PromptGenSample1}
          />
          <div>Text: A bed in room, style: photographic</div>
      </div>
      <br/>

      <div>
        <img className={classes.aboutDrawDreamerMoreImage}
          style={{ height: "150px", maxWidth: "100%" }}
          src={PromptGenSample2}
        />
        <div>
          Text: A bed in room, style: fantasy art
        </div>
      </div>
      <br/>

      <div>
        {t('aboutPage.38', 'More examples are found')}
        {' '}
        <a
            href={`${drawDreamerLink}`}
            target="_blank" rel="noreferrer noopener"
        >
          at Draw Dreamer Page
        </a>
      </div>
      <br/>
      <br/>

      <div>
        <div>
          <strong>
            Image to Image Generation (in Draw Dreamer)
          </strong>
        </div>
        <div>
            <p>
              Image to image generation 
              {' '}
              {t('aboutPage.43', 'allows users to manipulate images based on text prompt inputs.')} 
            </p>  
            <p>
              {t('aboutPage.44', 'By using input image as a guide for image generation, it generate new image that matches with text prompt input, and keep overall image structure.')}
            </p>
            {/* <p>
              Image to image generation generate new image based on input image and text prompt. 
            </p> */}
        </div>
        <br/>

        <div>
          Example of Image to Image Generation
        </div>
        <br/>

        <div className={classes.aboutDrawDreamerImages}>
            <div>
              <img className={classes.aboutDrawDreamerImage}
                src={ImageToImageInput}
              />
              <div>Input</div>
            </div>
            <div>
              <img className={classes.aboutDrawDreamerImage}
                src={ImageToImageOutput1}
              />
              <div>
                a woman wearing turban
              </div>
            </div>
            <div>
              <img className={classes.aboutDrawDreamerImage}
                src={ImageToImageOutput2}
              />
              <div>
                a man wearing turban
              </div>
            </div>
          </div>
          <br/>
          <br/>

          <div>
            <p>
              {t('aboutDrawDreamer02', 'It is possible to use image with input image and text prompt.')}
              {' '}(Image prompt)
            </p>
            <br/>
            <div className={classes.aboutDrawDreamerImages}>
              <p>
                <img
                  style={{ height: "150px", maxWidth: "100%" }}
                  src={ImageToImageIpaInput}
                />
                <div>Input image</div>
              </p>
              <br/>
              <p>
                <img
                  style={{ height: "150px", width: "150px" }}
                  src={ImageToImageIpaImage}
                />
                <div>
                  Image prompt
                </div>
              </p>
            </div>
            <br/>
            <p>
              <img
                style={{ height: "150px", width: "150px" }}
                src={ImageToImageIpaResult}
              />
              <div>
                portrait of a young woman, wearing turban
              </div>
            </p>
          </div>
      </div>
      <br/>
      <br/>


      <div>
        <div>
          <strong>
            Image Stylizer (in Draw Dreamer)
          </strong>
        </div>
        <div>
          <p>
            Image Stylizer{' '} 
            {t('aboutDrawDreamer03', 'is a tool to generate new input image that has styles similar to style images.')}
            {' '}
            {t('aboutDrawDreamer04', 'it is useful to create images with different styles.')}
          </p>  
          <p>
          </p>
          {/* <p>
            Image to image generation generate new image based on input image and text prompt. 
          </p> */}
        </div>
        <br/>

        <div>
          Example of Image Stylizer
        </div>
        <br/>

        <div className={classes.aboutDrawDreamerImages}>
            <div>
              <img className={classes.aboutDrawDreamerImage}
                style={{ maxHeight:"150px" }}
                src={ImageStylizerInput}
              />
              <div>Input image</div>
            </div>
            <div>
              <img className={classes.aboutDrawDreamerImage}
                style={{ maxHeight:"150px" }}
                src={ImageStylizerStyle}
              />
              <div>
                Style image
              </div>
            </div>
          </div>
          <br/>

          <div>
            <img className={classes.aboutDrawDreamerImage}
              style={{ maxHeight:"150px" }}
              src={ImageStylizerResult}
              />
            <div>
              Statue of a man
            </div>
          </div>
          <br/>
          <br/>

          <div>
            <p>
              {t('aboutDrawDreamer05', 'By using multiple style images, it is possible to generate images with more diverse styles.')}
            </p>
            <br/>
            <div className={classes.aboutDrawDreamerImages}>
              <p>
                <img
                  style={{ height: "150px", maxWidth: "100%" }}
                  src={ImageStylizerInput2}
                />
                <div>Input image</div>
              </p>
            </div>
            <br/>

            <div>
              <div className={classes.aboutDrawDreamerImages}
                style={{ gap: 0 }}
              >
                <div>
                  <img
                    style={{ height: "100px", maxWidth: "100%" }}
                    src={ImageStylizerStyle21}
                  />
                </div>
                <div>
                  <img
                    style={{ height: "100px", maxWidth: "100%" }}
                    src={ImageStylizerStyle22}
                  />
                </div>
                <div>
                  <img
                    style={{ height: "100px", maxWidth: "100%" }}
                    src={ImageStylizerStyle23}
                  />
                </div>
              </div>
              <div>
                Style images
              </div>
            </div>
            <br/>

            <p>
              <img
                style={{ height: "150px", width: "150px" }}
                src={ImageStylizerResult2}
              />
              <div>
                portrait of a woman
              </div>
            </p>
          </div>
      </div>
      <br/>
      <br/>


      <div>
        <div>
          <strong>
            Anime Image Generation (in Draw Dreamer)
          </strong>
        </div>
        <div>
          <p>
            Anime Image Generation
            {' '}
            {t('aboutDrawDreamer06', 'is a tool to generate anime style image from photo, realistic image, or other images.')}
          </p>  

        </div>
        <br/>

        <div>
          Example of Anime Image Generation
        </div>
        <br/>

        <div className={classes.aboutDrawDreamerImages}>
            <div>
              <img className={classes.aboutDrawDreamerImage}
                style={{ maxHeight:"150px" }}
                src={AnimeImageInput}
              />
              <div>Input image</div>
            </div>
            <div>
              <img className={classes.aboutDrawDreamerImage}
                style={{ maxHeight:"150px" }}
                src={AnimeImageOutput}
              />
              <div>
                Output image
              </div>
            </div>
          </div>
          <br/>

          <div>
            <p>
              {t('aboutDrawDreamer07', 'Diverse images can be genereted by changing text prompt input.')}
            </p>
            <br/>
            <div className={classes.aboutDrawDreamerImages}>
              <div>
                <img
                  style={{ height: "150px", maxWidth: "100%" }}
                  src={AnimeImageInput2}
                />
                <div>Input image</div>
              </div>
              <div>
                <img
                  style={{ height: "150px", width: "150px" }}
                  src={AnimeImageOutput2}
                />
                <div>
                  best quality
                </div>
              </div>
              <div>
                <img
                  style={{ height: "150px", width: "150px" }}
                  src={AnimeImageOutput22}
                />
                <div>
                  reading a book
                </div>
              </div>
              <div>
                <img
                  style={{ height: "150px", width: "150px" }}
                  src={AnimeImageOutput23}
                />
                <div>
                  wearing sun glasses
                </div>
              </div>
            </div>
          </div>
      </div>
      <br/>
      <br/>

      <div>
        <div>
          <strong>
            Style Fusion (in Draw Dreamer)
          </strong>
        </div>
        <div>
          <p>
            Style Fusion {' '}
            {t('aboutDrawDreamer08', 'is a tool to generate image that has styles similar to style images.')}            
            {' '}{t('aboutDrawDreamer09', 'It focuses on preserving input image content structures.')}
            {' '}
            <br/>
            {t('aboutDrawDreamer10', 'It is useful for creating images with diverse styles. ')}
            
          </p>  

        </div>
        <br/>

        <div>
          Example of Style Fusion
        </div>
        <br/>

        <div className={classes.aboutDrawDreamerImages}>
          <div>
            <img
              style={{ height: "150px", maxWidth: "100%" }}
              src={StyleFusionInput1}
            />
            <div>Input image</div>
          </div>
          <div>
            <img
              style={{ height: "150px", width: "150px" }}
              src={StyleFusionStyle1}
            />
            <div>
              Style Image
            </div>
          </div>
          <div>
            <img
              style={{ height: "150px", width: "150px" }}
              src={StyleFusionOutput1}
            />
            <div>
              Output
            </div>
          </div>
        </div>
        <br/>

        <div className={classes.aboutDrawDreamerImages}>
          <div>
            <img
              style={{ height: "150px", maxWidth: "100%" }}
              src={StyleFusionInput2}
            />
            <div>Input image</div>
          </div>
          <div>
            <img
              style={{ height: "150px", width: "150px" }}
              src={StyleFusionStyle2}
            />
            <div>
              Style Image
            </div>
          </div>
          <div>
            <img
              style={{ height: "150px", width: "150px" }}
              src={StyleFusionOutput2}
            />
            <div>
              Output
            </div>
          </div>
        </div>
        <br/>

      </div>
      <br/>
      <br/>

      <div>
      <div>
          <strong>
            Image Refiner (in Draw Dreamer)
          </strong>
        </div>
        <div>
          <p>
            Image Refiner 
            {' '}
            {t('aboutPage.45', 'is a tool to generate higher resolution image from small or unclear image.')}
          </p>
          <p>
            {t('aboutPage.46', 'By using input image as a guide for image generation, Image Refiner generate refined input image.')}
          </p>
          {/* <p>
            It is suitable for upscaling small images. Output size is 512px.
            <br/>
            (Image Refiner is not good at to refine image when input image is already higher resolution.)
          </p> */}
        </div>
        <br/>

        <div>
          Example of Image Refiner
        </div>
        <br/>

        <div className={classes.aboutDrawDreamerImages}>
            <div>
              <img 
                className={classes.aboutDrawDreamerRefinerImage}
                
                src={ImageRefinerInput}
              />
              <div>Input</div>
            </div>
            <div>
              <img 
                className={classes.aboutDrawDreamerRefinerImage}
                src={ImageRefinerOutput}
              />
              <div>
                output
              </div>
            </div>

          </div>
      </div>
      
    </div>
  );

  return <Fragment>{aboutDrawDreamerBody}</Fragment>;
};
export default AboutDrawDreamer;