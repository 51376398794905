// import logo from './logo.svg';

import { Fragment, useEffect } from 'react';
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
// import { useEffect, useState } from 'react';
// import { BrowserRouter as Switch, Route, useHistory, Link } from "react-router-dom";

import AboutPage from './pages/AboutPage/AboutPage';
import AdminPage from './pages/AdminPage/AdminPage';
import AuthCheck from './components/Auth/AuthCheck';
import AuthRequired from './components/Auth/AuthRequired';
import Backdrop from './components/Backdrop/Backdrop';
// import FileUpload from './components/FileUpload';
import Bucket from './components/BucketComponents/Bucket/Bucket';
import BucketObjects from './components/BucketComponents/BucketObject/BucketObjects';
// import FileFolderMove from './components/FileFolderMove';
// import FileMove from './components/FileMove';
// import Folder from './components/BucketComponents/Folder/Folder';
import GetAuth from './components/Auth/GetAuth';
import GetLoginUsers from './components/Auth/GetLoginUsers';
import GetWindowData from './components/UI/GetWindowData';
import GetDeferredPrompt from './components/UI/GetDeferredPrompt';
import HeadMetaTag from './components/UI/HeadMetaTag';
import ImageResizer from './components/ImageResizer/ImageResizer';
import Layout from './components/Layout/Layout';
import LamaCleaner from './components/LamaCleaner/LamaCleaner.tsx';
import Loader from './components/Loader/Loader';
import NotPageFound from './pages/NotPageFound/NotPageFound';
import PrivacyPolicy from './pages/PlivacyPolicy/PrivacyPolicy';
import SizeManagePage from './pages/SizeManagePage/SizeManagePage';
import TermsOfUse from './pages/TermsOfUse/TermsOfUse';
import UserInfo from './pages/UserInfo/UserInfo';
import Contact from './pages/Contact/Contact';

import Register from './pages/Subscription/Register';
import Subscribe from './pages/Subscription/subscribe/Subscribe';
import Cancel from './pages/Subscription/cancel/Cancel';
import ChangePlan from './pages/Subscription/change-plan/ChangePlan';
import Account from './pages/Subscription/account/Account';
import Prices from './components/subscription/prices/Prices';

import GetAllObjects from './components/BucketComponents/BucketObject/GetAllObjects';
import GetObjectMetadata from './components/BucketComponents/ObjectMetadata/GetObjectMetadata';
import GetSubscriptionData from './components/subscription/GetSubscriptionData';
import GetImageUrls from './components/BucketComponents/BucketObject/BucketObjectImageList/GetImageUrls.jsx';

import AdminBucketObjectList from './components/Admin/AdminBucketObject/AdminBucketObjectList';
// import FileUploadSocket from './components/BucketComponents/ObjectControl/FileUploadSocket';

import ToastUiImageEditor from './components/ImageEditor/ToastUiImageEditor';
import ImageCombiner from './components/ImageCombiner/ImageCombiner';
import ImageFusion from './components/ImageFusion/ImageFusion.js';
import ImageConvert from './components/ImageConvert/ImageConvert.js';

import ServiceBuckets from './components/BucketComponents/ServiceBuckets/ServiceBuckets';

// import ToolsDataSocket from './components/BucketComponents/ToolsData/ToolsDataSocket';
import SetUserNameData from './components/Auth/SetUserNameData';
import UserSocketConnect from './components/SocketConnect/UserSocketConnect.js';

// import BucketObjectAllList from './components/BucketObjectAllList';
import { useStore } from './hook-store/store';

import { 
  getCustomerDataSubscriptions, 
  // getCurrentSubscription, 
  // fetchPrices 
} from './utils/stripe';

import './App.css';




const lsUuid = localStorage.getItem('lsUuid') ? localStorage.getItem('lsUuid') : '';



//// dev urls
// export const BACKEND_URL = process.env.REACT_APP_DEV_BACKEND_URL;
// export const loginPageLink = process.env.REACT_APP_DEV_AUTHPAGE_URL + `/login?fromUrl=${encodeURIComponent(window.location.origin)}&lsUuid=${lsUuid}`;
// export const signupPageLink = process.env.REACT_APP_DEV_AUTHPAGE_URL + `/signup?fromUrl=${encodeURIComponent(window.location.origin)}`;
// export const SUBSCRIPTION_BACKEND_URL = BACKEND_URL;
// export const subscriptionBasicPriceId = process.env.REACT_APP_SUBPRICE_TEST_BASIC;
// export const subscriptionMiniPriceId = process.env.REACT_APP_SUBPRICE_TEST_MINI;
// export const drawDreamerLink = process.env.REACT_APP_DEV_DRAWDREAMER_URL;
// // export const dreamInpaniterLink = 'http://localhost:3000';
// export const dreamInpaniterLink = 'http://localhost:8504';
// // export const WATAKURAURL = 'http://localhost:8504';
// export const WATAKURAURL = 'http://localhost:8505';
// export const SHAREURLS = ['http://localhost:8503', 'http://localhost:8504','http://localhost:8505'];
// export const USERACCOUNTPAGE_URL = 'http://localhost:8506';


//// test deploy urls
// export const BACKEND_URL = process.env.REACT_APP_TEST_BACKEND_URL;
// export const loginPageLink = process.env.REACT_APP_AUTHPAGE_URL + `/login?fromUrl=${encodeURIComponent(window.location.origin)}&lsUuid=${lsUuid}`;
// export const signupPageLink = process.env.REACT_APP_AUTHPAGE_URL + `/signup?fromUrl=${encodeURIComponent(window.location.origin)}`;
// export const SUBSCRIPTION_BACKEND_URL = BACKEND_URL;
// export const subscriptionBasicPriceId = process.env.REACT_APP_SUBPRICE_BASIC;
// export const subscriptionMiniPriceId = process.env.REACT_APP_SUBPRICE_MINI;
// export const drawDreamerLink = process.env.REACT_APP_TEST_DRAWDREAMER_URL;
// export const dreamInpaniterLink = 'https://iopaint-frontend-test.web.app';
// export const WATAKURAURL = 'https://ud-gqlapi-front.firebaseapp.com';
// export const SHAREURLS = ['https://ud-gqlapi-front.firebaseapp.com'];
// export const USERACCOUNTPAGE_URL = 'https://casauthsso-userpage-test.web.app';


//// deploy urls
export const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
export const loginPageLink = process.env.REACT_APP_AUTHPAGE_URL + `/login?fromUrl=${encodeURIComponent(window.location.origin)}&lsUuid=${lsUuid}`;
export const signupPageLink = process.env.REACT_APP_AUTHPAGE_URL + `/signup?fromUrl=${encodeURIComponent(window.location.origin)}`;
export const SUBSCRIPTION_BACKEND_URL = BACKEND_URL;
export const subscriptionBasicPriceId = process.env.REACT_APP_SUBPRICE_BASIC;
export const subscriptionMiniPriceId = process.env.REACT_APP_SUBPRICE_MINI;
export const drawDreamerLink = process.env.REACT_APP_DRAWDREAMER_URL;
export const dreamInpaniterLink = 'https://kura-dream-inpainter.spaceeight.net';
export const WATAKURAURL = 'https://www.watakura.xyz';
export const SHAREURLS = ['https://www.watakura.xyz', 'https://watakura.xyz', 'https://ud-restapi-front.web.app'];
export const USERACCOUNTPAGE_URL = 'https://useraccount.spaceeight.net';





function App() {

  const [store, dispatch] = useStore();
  const { 
    isAuth, 
    gLoading, 
    srvLoading,
    metaLoading,
    isAdmin,
    isAllApis,
    // isBackendApi 
  } = store.uiStore;
  const { isStripeApi } = store.stripeData;
  const { isServiceBucketsApi } = store.serviceBucketsStore;
  // const isAuth = store.uiStore.isAuth;
  // const gLoading = store.uiStore.gLoading;
  // const isAdmin = store.uiStore.isAdmin;
  const bucketName = store.bucketObjectStore.bucketName;

  console.log('store-App.js', store);
  // console.log('isAuth bucketname', isAuth, bucketName);


  const userLoginEmail = localStorage.getItem("email")
  ? localStorage.getItem("email")
  : "";

  useEffect(() => {
    if (isServiceBucketsApi && isStripeApi) {
      dispatch('SET_ISALLAPIS', true);
    } else {
      dispatch('SET_ISALLAPIS', false);
    }
  }, [isStripeApi, isServiceBucketsApi]);


  useEffect(() => {
    const lsCustomerEmail = localStorage.getItem('customerEmail');
    
    if (userLoginEmail && lsCustomerEmail) {
      const token = localStorage.getItem('token');
    
      getCustomerDataSubscriptions(token, userLoginEmail)
        .then(result => {
          console.log('get sub result', result);

          if (result.data.length > 0) {
            const subs = result.data;
            // dispatch('SET_SUBSCRIPTIONS', subs);

            const remeetSubs = subs.filter(sub => {
              return sub.metadata.service === 'kura-storage';
            })

            dispatch('SET_SUBSCRIPTIONS', remeetSubs);
          }

        })
        .catch(err => {
          console.log('get sub error', err);
        });
    }
  }, [userLoginEmail]);



  let routesBody;

  if (isAuth) {
    routesBody = (
      <div>
        <Bucket />

        <Routes>
          {/* <Route path="test-route" 
            element={<div>test-route-content</div>} 
          /> */}
          {/* <Route path="userinfo" 
            element={<UserInfo />} 
          /> */}

          <Route path="privacypolicy" 
            element={<PrivacyPolicy />} 
          />
          <Route path="termsofuse" 
            element={<TermsOfUse />} 
          />
          <Route path="about" 
            element={<AboutPage />} 
          />

          <Route path="image-editor" 
            element={<ToastUiImageEditor />} 
          />

          <Route path="image-cleaner" 
            element={<LamaCleaner />} 
          />

          <Route path="image-resizer" 
            element={<ImageResizer />} 
          />

          <Route path="image-combiner" 
            element={<ImageCombiner />} 
          />

          <Route path="image-fusion" 
            element={<ImageFusion />} 
          />

          <Route path="image-convert" 
            element={<ImageConvert />} 
          />

          <Route path="contact" 
            element={<Contact />} 
          />
          {/* <Route path="image-draw-dreamer" element={<Tldreamer />}/> */}


          {bucketName && isAllApis && (
            <>
              <Route path="userinfo" 
                element={<UserInfo />} 
              />

              <Route path="/" 
                element={<BucketObjects />} 
              />

              <Route path="sizemanage" 
                element={<SizeManagePage />} 
              />

              {/* <Route path="/subscription"
                element={<Register />}
              /> */}

              <Route path="/subscription/prices"
                element={<Prices />}
              />

              <Route path="/subscription/subscribe"
                element={<Subscribe />}
              />
                  
              <Route path="/subscription/change-plan"
                element={<ChangePlan />}
              />

              <Route path="/subscription/account"
                element={<Account />}
              />

              <Route path="/subscription/cancel"
                element={<Cancel />}
              />
            </>
          )}



          {isAdmin && (
            <>
              <Route path="admin" 
                element={<AdminPage />} 
              />
              <Route path="admin/object-list" 
                element={<AdminBucketObjectList />} 
              />
            </>
          )}

          <Route path="*" 
            element={<NotPageFound />} 
          />
        </Routes>

      </div>

    )
  }
  else {
    routesBody = (
    <div>
      <Routes>

        <Route path="privacypolicy" 
          element={<PrivacyPolicy />} 
        />
        <Route path="termsofuse" 
          element={<TermsOfUse />} 
        />
        <Route path="about" 
          element={<AboutPage />} 
        />

        <Route path="image-editor" 
          element={<ToastUiImageEditor />} 
        />

        <Route path="image-cleaner" 
          element={<LamaCleaner />} 
        />

        <Route path="image-resizer" 
          element={<ImageResizer />} 
        />
        
        <Route path="image-combiner" 
          element={<ImageCombiner />} 
        />

        <Route path="image-fusion" 
          element={<ImageFusion />} 
        />
        
        <Route path="image-convert" 
            element={<ImageConvert />} 
        />
        
        <Route path="*" 
          // element={<AuthRequired />} 
          element={<NotPageFound />} 
        />
      </Routes>
    </div>
    )
  }


  return (
    <Fragment>
      {(gLoading || srvLoading || metaLoading) && (
        <div>
          <Backdrop 
            zIndex={'500'}
            onCancel={() => { 
              if (!gLoading) {
                // setClickedObjectKeyHandler(''); 
              }
            }}
          />
          <span className='gLoader'>
            <Loader />
          </span>
        </div>
      )}

      <BrowserRouter>
        <Layout>
          
          {routesBody}

          <GetWindowData />
          <GetDeferredPrompt />
          <HeadMetaTag />
  
          <GetAuth />
          <AuthCheck />
          
          <div style={{display:'none'}}>
            <GetLoginUsers />
          </div>

        </Layout>

        {/* <Register />
        <GetAllObjects /> */}

        {isAuth && (
          <div>
            <Register />
            <GetAllObjects />
            <GetObjectMetadata />
            <GetSubscriptionData />
            <GetImageUrls />

            <ServiceBuckets />

            <UserSocketConnect />
            {/* <ToolsDataSocket /> */}
            {/* <FileUploadSocket /> */}

            <SetUserNameData />
          </div>
        )}

        {/* <div>...</div> */}
      </BrowserRouter>
    </Fragment>
  );
}

export default App;
