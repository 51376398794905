import { useEffect, useState, Fragment } from "react";

import { ReactSearchAutocomplete } from 'react-search-autocomplete'

// import * as bucketObjectUtils from '../../../utils/bucket/bucket-object-util';

import { useStore } from '../../../hook-store/store';
import { createFileMark } from "../../../utils/bucket/bucket-ui-util";
import { isTrashboxObject } from "../../../utils/bucket/object-metadata-util";
import { getFolderNameFromKey } from "../../../utils/bucket/bucket-object-util";

// import { BACKEND_URL } from '../../../App';
import { marks } from "../../../utils/marks";
import classes from './ObjectSearch.module.css';

const searchBoxStyle = {
    // height: "35px",
    height: "2rem",
    // height: "2.5rem",
    border: "1px solid #dfe1e5",
    // border: "1px solid gray",
    // borderRadius: "24px",
    borderRadius: "6px",
    backgroundColor: "white",
    boxShadow: "rgba(32, 33, 36, 0.28) 0px 1px 6px 0px",
    hoverBackgroundColor: "#eee",
    color: "#212121",
    // fontSize: "16px",
    fontSize: "1rem",
    fontFamily: "Arial",
    iconColor: "grey",
    lineColor: "rgb(232, 234, 237)",
    placeholderColor: "grey",
    // clearIconMargin: '3px 14px 0 0',
    clearIconMargin: '3px 5px 0 0',
    // searchIconMargin: '0 0 0 16px',
    searchIconMargin: '0 0 0 5px',
    
    zIndex:"100",

};

function ObjectSearch(props) {
  // console.log('ObjectSearch.js-props', props);
  const { setSearchSelectedItem } = props;

  const [store, dispatch] = useStore();
  // console.log(store);
  const allBucketObjects = store.bucketObjectStore.allBucketObjects;
  const { allObjectsMetaList } = store.objectMetadataStore;
  // const bucketName = store.bucketObjectStore.bucketName;
  // const currentPrefix = store.bucketObjectStore.currentPrefix;

  // const [searchList, setSearchList] = useState([]);

  // useEffect(() => {
  //   if (allBucketObjects.length > 0) {
  //     const listForSearch = createSearchList(allBucketObjects);
  //     setSearchList(listForSearch);
  //   }

  // },[allBucketObjects]);
  
  const searchList = allBucketObjects.map((element, index) => {
    if (element.Key.endsWith('/')) {
      // return {
      //   id: index,
      //   Key: element.Key,
      //   name: element.Key.slice(0, element.Key.length - 1),
      //   LastModified: element.LastModified,
      //   Size: element.Size
      // }
      return {
        ...element,
        id: index,
        Key: element.Key,
        name: element.Key.slice(0, element.Key.length - 1),
        LastModified: element.LastModified,
        Size: element.Size
      }
    } 
    else {
      const timeEndIndex = element.Key.indexOf('Z');
      const originalName = element.Key.substring(timeEndIndex+2);

      // return {
      //   id: index,
      //   Key: element.Key,
      //   name: originalName,
      //   LastModified: element.LastModified,
      //   Size: element.Size
      // }
      return {
        ...element,
        id: index,
        Key: element.Key,
        name: originalName,
        LastModified: element.LastModified,
        Size: element.Size
      }
    }
  });
 
  // const createSearchList = (array) => {
  //   const list = array.map((element, index) => {
  //     if (element.Key.endsWith('/')) {
  //       return {
  //         id: index,
  //         key: element.Key,
  //         name: element.Key.slice(0, element.Key.length - 1),
  //         lastModified: element.LastModified,
  //         size: element.Size
  //       }
  //     } 
  //     else {
  //       const timeEndIndex = element.Key.indexOf('Z');
  //       const originalName = element.Key.substring(timeEndIndex+2);
  
  //       return {
  //         id: index,
  //         key: element.Key,
  //         name: originalName,
  //         lastModified: element.LastModified,
  //         size: element.Size
  //       }
  //     }
  //   });

  //   return list;
  // }


  // console.log(list);

  // const items = [
  //   {
  //     id: 0,
  //     name: 'Cobol',
  //     type: 'co',
  //   },
  //   {
  //     id: 1,
  //     name: 'JavaScript',
  //     type: 'js'
  //   },
  //   {
  //     id: 2,
  //     name: 'Basic',
  //     type: 'ba',
  //   },
  //   {
  //     id: 3,
  //     name: 'PHP',
  //     type: 'ph',
  //   },
  //   {
  //     id: 4,
  //     name: 'Java',
  //     type: 'jav',
  //   }
  // ];

  // if (localStorage.getItem('recentSearchSelect')) {
  //   console.log(JSON.parse(localStorage.getItem('recentSearchSelect')))
  //   // localStorage.removeItem('recentSearchSelect');
  // }
  
  const lsStoreRecentSelect = (selectObj) => {
    const storeName = `recentSearchSelect${localStorage.getItem('email')}`;

    const lsRecentSelect = localStorage.getItem(storeName);

    if (!lsRecentSelect) {
      localStorage.setItem(storeName, JSON.stringify([{
        ...selectObj, 
        addAt: Date.now()
      }]));
    } else {
      const parsedSelect = JSON.parse(lsRecentSelect);

      let addedList;

      if (parsedSelect.length > 200) {
        addedList = parsedSelect.slice(-200).concat({
          ...selectObj, 
          addAt: Date.now()
        });

        localStorage.setItem(storeName, JSON.stringify(addedList));
      } 
      else {
        addedList = parsedSelect.concat({
          ...selectObj,
           addAt: Date.now()
        });

        localStorage.setItem(storeName, JSON.stringify(addedList));
      }
    }
  }


  const handleOnSearch = (string, results) => {
    // onSearch will have as the first callback parameter
    // the string searched and for the second the results.

    console.log(string, results)
    if (!string) {
      dispatch('SET_SEARCH_SELECTEDITEM', null);
      // setSearchSelectedItem(null);
    }
  }

  const handleOnHover = (result) => {
    // the item hovered
    // console.log(result);
  }

  const handleOnSelect = (item) => {
    const isTrashObject = isTrashboxObject(item.Key, allObjectsMetaList);

    if (!isTrashObject) {
      // the item selected
      // console.log('selected item: ', item);
      dispatch('SET_SEARCH_SELECTEDITEM', item);
      // setSearchSelectedItem(item);
      lsStoreRecentSelect(item);
    }
  }

  const handleOnFocus = () => {
    // console.log('Focused')
    // console.log(items);
  }

  const handleOnClear = () => {
    // console.log('Cleared')
    dispatch('SET_SEARCH_SELECTEDITEM', null);
    // console.log(items);
    // setSearchSelectedItem(null);
  }

  const formatResult = (item) => {

    const isTrashObject = isTrashboxObject(item.Key, allObjectsMetaList);

    if (item.Key.endsWith('/')) {
      if (!isTrashObject) {
        const folderName = getFolderNameFromKey(item.Key);

        const splitList = item.Key.split('/');
        splitList.pop();
        splitList.pop();
        const elementParentFolderKey = splitList.join('/') + '/';
        // const elementParentFolderKey = splitList.join('/');

        return (
          <span style={{ display: 'block', textAlign: 'left', paddingBottom: "0.25rem", wordBreak:"break-all" }}>
            <strong>
              {marks.folderFillMark}{' '} 
              {/* {folderName} */}
              {decodeURI(folderName)}
            </strong>
            <span style={{ display: 'block', textAlign: 'left', paddingBottom: "0.25rem", paddingLeft: "0.5rem", wordBreak:"break-all" }}>
              {/* {elementParentFolderKey} */}
              {decodeURI(elementParentFolderKey)}
            </span>
          </span>
        );
      } else {

        let folderName;
        const trashFolderName = getFolderNameFromKey(item.Key);
        
        if (trashFolderName.startsWith('trash-')) {
          const trashYIndex = trashFolderName.indexOf('Y');
          folderName = trashFolderName.substring(trashYIndex + 2);
        }

        if (folderName) {
          return (
            <span style={{ display: 'block', textAlign: 'left', paddingBottom: "0.25rem", wordBreak:"break-all" }}
              title={decodeURI(folderName)}
            >
              <strong>
                {marks.folderFillMark}{' '}
                {/* {folderName} */}
                {decodeURI(folderName)}
              </strong>
              <span style={{ display: 'block', textAlign: 'left', paddingBottom: "0.25rem", paddingLeft: "0.5rem", wordBreak:"break-all" }}>
                trash box
              </span>
            </span>
          );
        }
      }

    } else {
      if (!isTrashObject) {
        const prefixDivide = item.Key.split('/');
        prefixDivide.pop();
        // const folderPlace = prefixDivide.join('/');
        const folderPlace = prefixDivide.join('/') + '/';

        return (
          <>
            <span style={{ display: 'block', textAlign: 'left', paddingBottom: "0.25rem", wordBreak:"break-all" }}>
              <strong>{createFileMark(item)}{' '}
                {/* {item.name} */}
                {decodeURI(item.name)}
              </strong>
            </span>
            <span style={{ display: 'block', textAlign: 'left', paddingBottom: "0.25rem", paddingLeft: "0.5rem", wordBreak:"break-all" }}>
              {/* {folderPlace} */}
              {decodeURI(folderPlace)}
            </span>
          </>
        );
      } else {
        const folderPlace = 'trash box';
        
        return (
          <>
            <span style={{ display: 'block', textAlign: 'left', paddingBottom: "0.25rem", wordBreak:"break-all" }}>
              <strong>
                {createFileMark(item)}{' '}
                {/* {item.name} */}
                {decodeURI(item.name)}
              </strong>
            </span>
            <span style={{ display: 'block', textAlign: 'left', paddingBottom: "0.25rem", paddingLeft: "0.5rem", wordBreak:"break-all" }}>
              {/* {folderPlace} */}
              {decodeURI(folderPlace)}
            </span>
          </>
        );
      }
    }
  };


  return (
    <Fragment>
      {searchList.length > 0 && (
        <div className={classes.adHostSearch}
          // style={{ width: 400 }}
        >
          <ReactSearchAutocomplete
            // items={items}
            items={searchList}
            onSearch={handleOnSearch}
            onHover={handleOnHover}
            onSelect={handleOnSelect}
            onFocus={handleOnFocus}
            onClear={handleOnClear}
            // autoFocus
            formatResult={formatResult}

            fuseOptions={{ keys: ["name"] }}
            resultStringKeyName="name"

            placeholder="search"
            styling={searchBoxStyle}
          />
        </div>
      )} 
    </Fragment>
  );
}

export default ObjectSearch;
