import { Fragment, useState, useEffect } from 'react';
import _ from 'lodash';
import numeral from 'numeral';
import moment from 'moment';
// import axios from 'axios';

// import FileActions from '../FileAction/FileActions';
// import FileNameChange from '../FileAction/FileNameChange';
// import FilesUpload from '../ObjectControl/FilesUpload';
import FolderActions from '../FolderAction/FolderActions';
import FolderNameChange from '../FolderAction/FolderNameChange';
// import PrefixLine from '../PrefixLine';
// import SelectedObjectsControll from '../../SelectedObjects/SelectedObjectsControl';

import { useStore } from '../../../../hook-store/store';

// import * as bucketObjectUtils from '../utils/bucket-object-util';
// import * as fileMoveUtils from '../../../../utils/bucket/file-move-util';

import { marks } from '../../../../utils/marks';

// import BucketObjectMenu from '../BucketObjectMenu';
import Backdrop from '../../../Backdrop/Backdrop';
import ModalPosition from '../../../Modal/ModalPosition';
import ModalConfirm from '../../../Modal/ModalConfirm';

// import { BACKEND_URL } from '../../../App';

import classes from './BucketObjectFolderItem.module.css';

// import ObjectControl from '../ObjectControl/ObjectControl';

function BucketObjectFolderItem(props) {

  const {
    object,
    folderClickSelectHandler,
    setClickedObjectKeyHandler, 
    showActionModalHandler,
    setClickedActionInfoHandler,
    clickedObjectKey,
    setClickedActionInfo,
    modalPositions,
    deleteFolderHandler,
    setMovePrefixesHandler,
    clickedActionInfo,
    getAllBucketObjects,
    storeObjectAccessHandler,
    imageStyle,
    actionMoadalStyle,
    createToplevelsHandler,
    isLoading,
  } = props;

  const checkMark = marks.checkMark;
  const noCheckMark = marks.noCheckMark;

  const [store, dispatch] = useStore();
  // console.log('store-BucketObjects.js', store);
  const currentPrefix = store.bucketObjectStore.currentPrefix;
  const bucketName = store.bucketObjectStore.bucketName;
  const selectedFileKeys = store.bucketObjectStore.selectedFileKeys;
  const movePrefix = store.bucketObjectStore.movePrefix;
  const movePrefixes = store.bucketObjectStore.movePrefixes;
  const topLevels = store.bucketObjectStore.topLevels;

  const { allBucketObjects } = store.bucketObjectStore;

  const gLoading = store.uiStore.gLoading;
  const windowValues = store.uiStore.windowValues;

  // const [clickedObjectKey, setClickedObjectKey] = useState('');
  // const [clickedActionInfo, setClickedActionInfo] = useState();
 
  // const [modalPositions, setModalPositions] = useState({ 
  //   top: null, 
  //   left: null,
  //   right: null,
  //   bottom: null,
  //   heightLimit: 300,
  // });

  // const [isLoading, setIsLoading] = useState(false);
  const isInSelectedFileKeys = selectedFileKeys.find(key => {
    return key === object.Key;
  });

  const folderActionElementId= `folder-action-${object.Key}`;
  // const fileActionElementId = `file-action-${object.Key}`;

  const isInMovePrefixes = movePrefixes.find(px => {
    return px === object.Key;
  });
  
  // const fileName = object.Key.split('/')[object.Key.split('/').length - 1]
  // const timeEndIndex = fileName.indexOf('Z');
  // // console.log(timeEndIndex);
  // const originalName = fileName.substring(timeEndIndex+2);
  const originalName = object.Key.substring(currentPrefix.length, object.Key.length -1);

  // const displayFolderName = object.Key.length > 20 
  //   ? originalName.slice(0, 20) + '...'
  //   : originalName;

  let bucketObjectFolderItemBody;

  if (originalName === '/') {
    // return null;
    bucketObjectFolderItemBody = null;
  }
  else {
    bucketObjectFolderItemBody = (
      <div className={classes.objectFolderItem}>
        <span
          title={decodeURI(originalName)}
          style={imageStyle}
          onClick={() => {    
            if (!isLoading) {
              // getAllBucketObjects(bucketName, object.Key); 
              createToplevelsHandler(object.Key, allBucketObjects);
              storeObjectAccessHandler(object.Key);
            }            
          }}
        >
          <span className={classes.objectFolderItemFolderMark}>
           {marks.folderFillMark} 
          </span>
          <span className={classes.objectFolderItemFolderName}>
            {/* {displayFolderName} */}
            {/* {decodeURI(displayFolderName)} */}
            {decodeURI(originalName)}
          </span>
        </span>

          {/* {isInMovePrefixes ? 'sele' : 'dese'} */}

          <span
            className={classes.objectFolderItemCheck}
            onClick={() => { 
              if (!isLoading) {
                folderClickSelectHandler(isInMovePrefixes, object.Key);
              }
            }}
          >
            {isInMovePrefixes &&
              <span>
                {checkMark}
              </span> 
            }
            {!isInMovePrefixes && (
              <span>
                {noCheckMark}
              </span>
            )}
        </span>
                   
        <span // id={folderActionElementId}
          className={classes.objectFolderItemAction}
          title={'select action for folder'}
          onClick={() =>{ 
            if (!isLoading) {
              setClickedObjectKeyHandler(object.Key); 
              showActionModalHandler(object.Key, 'folder');
              setClickedActionInfoHandler(null); 
            }
          }}
        >
          action {marks.triangleDown}
        </span>
        
        <span id={folderActionElementId}
          className={classes.objectFolderItemBottomLeft}
        >
        </span>

        {clickedObjectKey === object.Key && (
          <div>
            <Backdrop 
              zIndex={'90'}
              // backgroundColor={'rgba(0, 0, 0, 0.1)'}
              backgroundColor={'rgba(0, 0, 0, 0)'}
              onCancel={() => { 
                if (!isLoading) {
                  setClickedObjectKeyHandler(''); 
                  setClickedActionInfo(null);
                }
              }}
            />
            <ModalPosition 
              top={modalPositions.top}
              left={modalPositions.left}
              right={modalPositions.right}
              bottom={modalPositions.bottom}
              heightLimit={modalPositions.heightLimit}
              zIndex='100'
              // modalStyle={actionMoadalStyle}
              // onClose={() => {
              //   setClickedObjectKeyHandler(''); 
              //   setClickedActionInfo(null);
              // }}
            >
              {/* <div style={{textAlign:'right'}}>
                <span style={{cursor:'pointer'}} 
                  onClick={() => {
                    if (!isLoading) {
                      setClickedObjectKeyHandler(''); 
                      setClickedActionInfo(null);
                    } 
                  }}
                >
                  X
                </span>
              </div> */}
              <FolderActions 
                deleteFolderHandler={deleteFolderHandler}
                folderClickSelectHandler={folderClickSelectHandler}
                setMovePrefixesHandler={setMovePrefixesHandler}
                setClickedActionInfoHandler={setClickedActionInfoHandler}
                setClickedObjectKeyHandler={setClickedObjectKeyHandler}
                clickedActionInfo={clickedActionInfo}
                object={object}
                isInMovePrefixes={isInMovePrefixes}
                isLoading={isLoading}
              />
            </ModalPosition>
          </div>
        )}
      </div>
    )
  }

  let folderNameChangeBody;

  if (clickedActionInfo && clickedActionInfo.key === object.Key && clickedActionInfo.name === 'change-folder-name') {
    folderNameChangeBody = (
      <ModalConfirm 
        hideButtons={true}
        onClose={() => {
          if (!isLoading) {
            setClickedActionInfoHandler(null);
          }
        }}
      >
        <FolderNameChange 
          setClickedActionInfoHandler={setClickedActionInfoHandler}
          object={object}
        />
      </ModalConfirm>
    );
  }
    


  return (
    <Fragment>
      <div>{bucketObjectFolderItemBody}</div>
      <div>{folderNameChangeBody}</div>
    </Fragment>
  );
}

export default BucketObjectFolderItem;
