import React from 'react';
import { Fragment, useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import numeral from 'numeral';
import { useRecoilState } from 'recoil'
import {
  fileState,
} from '../../store/Atoms.tsx'
import {
  srcToFile,
} from '../../utils.ts'
// import Backdrop from '../../Backdrop/Backdrop';
import Folder from '../../../BucketComponents/Folder/Folder';
// import Modal from '../../Modal/Modal';
import Loader from '../../../Loader/Loader';

import { useStore } from '../../../../hook-store/store';
import * as uploadUtils from '../../../../utils/bucket/bucket-upload-util';
import { BACKEND_URL, loginPageLink, signupPageLink } from '../../../../App';


// import { BACKEND_URL, loginPageLink, signupPageLink } from '../../../App';
import { marks } from '../../../../utils/marks.js';
import './UploadEditFile.css';
// import "./tui-image-editor.css";
// import "./FileControl.css";

export default function FileUploadModalContent(props) {
  const {} = props;

  const [file, setFile] = useRecoilState(fileState);

  const [t] = useTranslation('translation');
  // console.log('UploadEditFile-props', props);

  const [store, dispatch] = useStore();
  const { storeRenders } = store.lamaCleanerStore;
  const { 
    bucketName, 
    topLevels, 
    totalSizeGB, 
    limitGB,
    selectedPrefix,
  } = store.bucketObjectStore;
  const { gLoading, isAuth, isAllApis } = store.uiStore;
  // const [imageData, setImageData] = useState();
  // const [selectedFile, setSelectedFile] = useState();
  
  // const [filePath, setFilePath] = useState('');
  const [fileName, setFileName] = useState('');
  const [outputFormat, setOutputFormat] = useState('');
  const [inputError, setInputError] = useState('');

  const [isLoading, setIsLoading] = useState();


  useEffect(() => {
    if (gLoading) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  },[gLoading]);

  useEffect(() => {
    if (file) {
      // const fileName = file.name.replace(/(\.[\w\d_-]+)$/i, `-${Date.now()}-cleanup$1`);
      const fileName = file.name;
      const fileSeparate = fileName.split('.');
      const ext = fileSeparate.pop();
      // setFileName(fileSeparate.join('.') + '-clean');
      setFileName(`cleanup-${Date.now()}`);
      setOutputFormat(ext);
    }
  },[file]);

  const setFileNameHandler = (event) => {
    // setFileName(event.target.value + '.' + outputFormat);
    setInputError('');
    const newName = event.target.value;

    if (newName.includes('/')) {
      setInputError('name should not have "/"');
    }

    if (newName.length > 100) {
      setInputError('maximum name length is 100');
    }

    if (!newName) {
      setInputError('name is required');
    }
    
    setFileName(newName);

    // if (event.target.valuse && event.target.value.includes('/')) {
    //   const separates = event.target.value.split('/');
    //   setFileName(separates.join(''));
    // }
  };

  const getCurrentRender = async (renders) => {
    let targetFile = file
    if (renders.length > 0) {
      const lastRender = renders[renders.length - 1]
      targetFile = await srcToFile(lastRender.currentSrc, file.name, file.type)
    }
    return targetFile
  };


  const cleanImageUploadHandler = async (path, fileName, uploadFile, isOriginal) => {
    try {
      // setIsLoading(true);
      dispatch('SET_GLOADING', true);

      let resultsForAction = {
        fileUpload: null,
      };


      if (!file) {
        throw new Error('error occured');
      }

      let useFile;
      // const name = file.name.replace(/(\.[\w\d_-]+)$/i, `-${Date.now()}-cleanup$1`)
      // const curRender = renders[renders.length - 1];
      
      if (!isOriginal) {
        const currentRenderFile = await getCurrentRender(storeRenders);
        useFile = new File([currentRenderFile], fileName, {type: currentRenderFile.type});
      } else {
        useFile = new File([uploadFile], fileName, { type: uploadFile.type });
      }

      // const uploadFile = await uploadFileCreate(curRender.currentSrc, name, file.type);
      
      const files = [useFile];
      // console.log('file, files', file, files);
      // console.log(currentRenderFile);
      // throw new Error('error-error');

      if (!files || files.length === 0) {
        throw new Error('upload file is required');
      }

      if (files.length > 1) {
        throw new Error('Upload limit is 1 file');
      }

      let uploadSizeGB = 0;
      // let limitGB = 0.1;

      for (const file of files) {
        uploadSizeGB = uploadSizeGB + file.size / 10**9;
      }

      // console.log('uploadSizeGB', uploadSizeGB, totalSizeGB);

      // const activeSubscription = subscriptions.find(subscription => {
      //   return subscription.status === 'active';
      // });

      // if (activeSubscription) {
      //   limitGB = parseInt(activeSubscription.metadata.storeSizeGB);
      // }
      
      if ((totalSizeGB + uploadSizeGB) > limitGB) {
        throw new Error('Total file sizes are more than limit');
      }


      // console.log(hasActiveSubscription, hasActiveSubscription2);


      let result;

      // result = await uploadUtils.s3fileUpload(
      //   BACKEND_URL,
      //   localStorage.getItem('token'),
      //   path,
      //   files,
      //   bucketName,
      // );
      result = await uploadUtils.s3fileUpload2(
        BACKEND_URL,
        localStorage.getItem('token'),
        path,
        files,
        bucketName,
        dispatch,
        'imageCleaner',
      );

      console.log(result);
      
      resultsForAction.fileUpload = result.data;

      // setSelectedFiles(null);

      const addedList = topLevels.concat(result.data.data);
      dispatch('SET_TOPLEVELS', addedList);

      // setSelectedFiles([]);
      // setDroppedFiles([]);
      // setShowDropArea(false);


      dispatch('SET_OBJECTACTION', {
        actionType: 'file-upload',
        delete: false,
        add: true,
        result: resultsForAction,
      });

      dispatch('SET_DATAFORMETADATA', result.data.dataForMetadata);

      dispatch('SHOW_NOTIFICATION', {
        status: '',
        title: 'upload success',
        message: '',
      });

      setTimeout(() => {
        dispatch('CLEAR_NOTIFICATION');
      },1000 * 3);

      // showUploadMoadlHandler(false);
      dispatch('SET_SHOW_LAMAUPLOADMODAL', false);
      
      // setIsLoading(false);
      dispatch('SET_GLOADING', false);

    } catch(err) {
      console.log(err);

      // setSelectedFiles([]);
      // setDroppedFiles([]);

      //// set isUploadFailed to get services-info again
      dispatch('SET_ISUPLOADFAILED', true);

      dispatch('SHOW_NOTIFICATION', {
        status: 'error',
        title: `upload failed. ${err.message && err.message}`,
        message: '',
      });

      setTimeout(() => {
        dispatch('CLEAR_NOTIFICATION');
      },1000 * 10);

      
      // setIsLoading(false);
      dispatch('SET_GLOADING', false);
    }

  };

 

  let editFileModalContent;

  if (isAuth && isAllApis) {
    editFileModalContent = (
      <div className='fileUploadModalContent'>
        <section className='uploadEditFileSection'>
          <label className='uploadEdiFileLabel'>
            {t('imageEditor.02', 'File name')}
          </label>
          <input className='inputBase uploadEdiFileInput'
            style={{ width:"80%" }}
            type="text"
            defaultValue={fileName}
            value={fileName}
            onChange={setFileNameHandler}
          />
  
          .{outputFormat}
          {/* {outputFormat === 'jpeg' && (
            <span>.jpeg</span>
          )}
          {outputFormat === 'png' && (
            <span>.png</span>
          )} */}
  
          <div>{inputError}</div>
        </section>
  
        <section className='uploadEditFileSection'>
          <Folder />
        </section>
  
        {file && (
          <section className='uploadEditFileSection'>
            {t('imageEditor.08', 'File size')}: {numeral(file.size).format('0.0b')}
          </section>
        )}
  
        <section className='uploadEditFileSection'>
          <div>
            <button className="btnBase"
              style={{ fontSize: "1.5rem", fontWeight: "bold", padding:"0.5rem"}}
              disabled={!fileName || isLoading}
              // onClick={() => { saveImageToDisk(filePath); }}
              onClick={() => { 
                if (!isLoading) {
                  cleanImageUploadHandler(
                    selectedPrefix, 
                    fileName + '.' + outputFormat,
                    file,
                    false,
                   );
                }
                // saveImageToDisk(fileName); 
              }}
            >
              {t('imageEditor.01', 'Store image file')}
            </button>
          </div>
        </section>
  
        {file && (
          <section className='uploadEditFileSection'>
            <div>
              {t('imageCleaner14', 'Input file')}
            </div>
            <div>
              <img 
                style={{maxHeight: '50px'}}
                src={URL.createObjectURL(file)}
              />
            </div>
            <div>
              <button className="btnBase"
                disabled={!fileName || isLoading}
                // onClick={() => { saveImageToDisk(filePath); }}
                onClick={() => { 
                  if (!isLoading) {
                    cleanImageUploadHandler(
                      selectedPrefix, 
                      file.name,
                      file,
                      true,
                    );
                  }
                  // saveImageToDisk(fileName); 
                }}
              >
                {t('imageCleaner15', 'Store input file')}
              </button>
            </div>
          </section>
        )}
  
  
  
        {isLoading && (<Loader />)}
  
        {/* {originalFile && (
          <img style={{maxWidth: "50%"}}
          src={URL.createObjectURL(originalFile)}/>
        )} */}
      </div>
    );
  }


  if (!isAuth) {
    editFileModalContent = (
      <div className='fileUploadModalContent'>
        <div className='uploadEditFileSection'>
          {t('aboutPage.09', 'Login to upload and store files, or create account')}
        </div>

        <div className='uploadEditFileSection'>
          <a href={loginPageLink}>
            <button className="btnBase">
              {marks.signInMrak} {t('aboutPage.10', 'Login')}
            </button>
          </a>
          <span> {t('aboutPage.11', 'or')} </span>
          <a href={signupPageLink}>
            <button className="btnBase">
              {marks.userPlusMrak} {t('aboutPage.12', 'Signup')}
            </button>
          </a>
        </div>
      </div>
    );
  }


  return (
    <Fragment>
      <div>
        {editFileModalContent}
      </div>
    </Fragment>
  );
}

