import React from 'react';
import { Fragment, useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';

import Backdrop from '../Backdrop/Backdrop';
import Modal from '../Modal/Modal';
import ResizerUploadModalContent from './ResizerUploadModalContent';

import { useStore } from '../../hook-store/store';

import { marks } from '../../utils/marks';


export default function ResizerUpload(props) {
  const { 
    renders,
  } = props;

  const [t] = useTranslation('translation');
  // console.log('UploadEditFile-props', props);

  const [store, dispatch] = useStore();
  const { 
    resizedFiles,
  } = store.imageResizerStore;
  const { gLoading, isAuth, isAllApis } = store.uiStore;

  const [showUploadModal, setShowUploadModal] = useState(false);
  const [isLoading, setIsLoading] = useState();


  useEffect(() => {
    if (gLoading) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  },[gLoading]);



  const showUploadMoadlHandler = (value) => {
    setShowUploadModal(value);
    // dispatch('SET_SHOW_LAMAUPLOADMODAL', value);
  }

 

  let imageResizerUploadBody;

  if (isAuth && isAllApis) {
    imageResizerUploadBody = (
      <button
        className='btnBase'
        disabled={resizedFiles.length === 0}
        onClick={() => {
          if (!isLoading) {
            showUploadMoadlHandler(true);
          }
          // cleanImageUploadHandler(''); 
        }}
      >
        {marks.uploadMark} {t('resizer14', 'Upload resized images')}
        {resizedFiles.length > 0 && (
          <span> ({resizedFiles.length})</span>
        )}
      </button>
    );
  }


  return (
    <Fragment>
      {imageResizerUploadBody}
      {showUploadModal && (
        <div>
          <Backdrop 
            onCancel={() => { 
              if (!isLoading) {
                showUploadMoadlHandler(false);
              }
            }}
          />
          <Modal
            onClose={() => { 
              if (!isLoading) {
                showUploadMoadlHandler(false);
              }
            }}
          >
            <ResizerUploadModalContent 
              showUploadMoadlHandler={showUploadMoadlHandler}
            />
          </Modal>
        </div>
      )}
    </Fragment>
  );
}

