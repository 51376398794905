import React from 'react';
import { Fragment, useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import numeral from 'numeral';

// import Backdrop from '../../Backdrop/Backdrop';
import Folder from '../../BucketComponents/Folder/Folder';
// import Modal from '../../Modal/Modal';
import Loader from '../../Loader/Loader';

import { useStore } from '../../../hook-store/store';

// import * as uploadUtils from '../../../utils/bucket/bucket-upload-util';

// import { BACKEND_URL, loginPageLink, signupPageLink } from '../../../App';

import './UploadEditFile.css';
// import "./tui-image-editor.css";
// import "./FileControl.css";

function UploadEditFileModalContent(props) {
  const { 
    // editorRef,
    // undoStackLength,
    // loadedImageName,
    fileName,
    setFileNameHandler,
    outputFormat,
    editFile,
    saveImageToDisk,
    inputError,
    isLoading,
  } = props;

  const [t] = useTranslation('translation');
  console.log('UploadEditFile-props', props);

  const [store, dispatch] = useStore();
  const { loadedImageName, undoStackLength } = store.imageEditorStore;

  // const { 
  //   bucketName, 
  //   topLevels, 
  //   totalSizeGB, 
  //   limitGB,
  //   selectedPrefix,
  // } = store.bucketObjectStore;
  // const { isAuth } = store.uiStore;


  // const [imageData, setImageData] = useState();
  // const [selectedFile, setSelectedFile] = useState();
  
  // const [filePath, setFilePath] = useState('');
  // const [fileName, setFileName] = useState('');
  // const [showUploadModal, setShowUploadModal] = useState(false);
  // const [outputFormat, setOutputFormat] = useState('png');
  // const [editFile, setEditFile] = useState();

  // const [isLoading, setIsLoading] = useState();

 

  let editFileModalContent;

  editFileModalContent = (
    <div>
      <section className='uploadEditFileSection'>
        <label className='uploadEdiFileLabel'>
          {t('imageEditor.02', 'File name')}
        </label>
        <input className='inputBase uploadEdiFileInput'
          style={{ width:"80%" }}
          type="text"
          defaultValue={fileName}
          onChange={setFileNameHandler}
        />

        .{outputFormat}
        {/* {outputFormat === 'jpeg' && (
          <span>.jpeg</span>
        )}
        {outputFormat === 'png' && (
          <span>.png</span>
        )} */}

        <div>{inputError}</div>

      </section>

      <section className='uploadEditFileSection'>
        <Folder />
      </section>

      {editFile && (
        <section className='uploadEditFileSection'>
          {t('imageEditor.08', 'File size')}: {numeral(editFile.size).format('0.0b')}
        </section>
      )}

      <section className='uploadEditFileSection'>
        <div>
          <button className="btnBase"
            disabled={!loadedImageName || !fileName || isLoading}
            // onClick={() => { saveImageToDisk(filePath); }}
            onClick={() => { saveImageToDisk(fileName); }}
          >
            {t('imageEditor.01', 'Store image file')}
          </button>
        </div>
      </section>

      {isLoading && (<Loader />)}
    </div>
  );


  return (
    <Fragment>
      <div>
        {editFileModalContent}
      </div>
    </Fragment>
  );
}

export default UploadEditFileModalContent;
