import { useState, useEffect, Fragment } from "react";
import { useTranslation } from "react-i18next";

import EditLinkImageStylizerIpaImages from "./EditLinkImageStylizerIpaImages";
import FolderAndFile from "../../FolderAndFile/FolderAndFile";

import { useStore } from "../../../../hook-store/store";
import { isForImageStylizerFile } from "../../../../utils/bucket/image-file-edit-util";

import { drawDreamerLink } from "../../../../App";

import classes from "./EditLinkModal.module.css";

function EditLinkImageStylizer(props) {
  const {
    smallImageUrl,
  } = props;

  // console.log('FileActions.js-props', props, object.Key);
  const [t] = useTranslation("translation");

  const [store, dispatch] = useStore();
  const { 
    editLinkModalData,
    imageUrlList,
    allBucketObjects,
   } = store.bucketObjectStore;
  // const { allObjectsMetaList } = store.objectMetadataStore;
  const { gLoading } = store.uiStore;

  
  const [ipaImageKeys, setIpaImageKeys] = useState([]);
  const [paramData, setParamData] = useState({
    image: {
      key: editLinkModalData.key,
      mimeType: editLinkModalData.mimeType,
    },
    ipaImages: []
  });
  const [isLoading, setIsLoading] = useState(false);

  // console.log('paramData', paramData);
  // console.log('paramData ipaImageKeys', ipaImageKeys);

  useEffect(() => {
    if (gLoading) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [gLoading]);

  const addIpaImageKeysHandler = (file) => {
    // console.log(file);
    if (ipaImageKeys.length >= 3) {
      return;
    }

    if (!file.mimeType || !isForImageStylizerFile(file.mimeType)) {
      return;
    }

    const ipaKeys = ipaImageKeys;

    const imageUrlObj = imageUrlList.find(element => {
      return element.key === file.Key;
    });

    const addedKeys = ipaKeys.concat({
      id: Math.random(),
      key: file.Key,
      imageUrlObj: imageUrlObj,
      mimeType: file.mimeType,
    });

    const paramIpaKeys = addedKeys.map(ele => {
      return {
        id: ele.id,
        key: ele.key,
        mimeType: ele.mimeType,
      }
    });

    setIpaImageKeys(addedKeys);
    setParamData({
      image: {
        key: editLinkModalData.key,
        mimeType: editLinkModalData.mimeType,
      },
      ipaImages: paramIpaKeys
    })

    dispatch('SHOW_NOTIFICATION', {
      status: '',
      title: 'image added',
      message: '',
    });

    setTimeout(() => {
      dispatch('CLEAR_NOTIFICATION');
    },1000 * 2);

  };

  const targetUrl = `${drawDreamerLink}/?keyData=${JSON.stringify(paramData)}&imagePhotoUserId=${localStorage.getItem('userId')}&openApp=imageStylizer`
  

  let editLinkImageStylizerBody;

  editLinkImageStylizerBody = (
    <div>
      <div className={classes.editLinkSection}>
        <div className={classes.editLinkTitle}>
          open files in Image Stylizer
        </div>
      </div>
      <div className={classes.editLinkSection}>
        <div>input image</div>
        <img className={classes.editLinkImage}
          src={smallImageUrl} 
        />
      </div>

      {ipaImageKeys.length === 0 && (
        <div className={classes.editLinkSection}>
          <a 
            href={targetUrl}
            target="_blank" rel="noreferrer noopener"
            // style={}
          >
            <button className="btnBase">
              open file in Image Stylizer
            </button>
          </a>
        </div>
      )}

      {/* <div>
        {JSON.stringify(paramData)}
      </div> */}
      <div className={classes.editLinkSection}>
        <div>style images (up to 3 images)</div>
        <div>
          <EditLinkImageStylizerIpaImages 
            ipaImageKeys={ipaImageKeys}
            setIpaImageKeys={setIpaImageKeys}
            paramData={paramData}
            setParamData={setParamData}
            targetUrl={targetUrl}
          />
        </div>
      </div>
      <div className="">
        <div>
          <strong>
            select style images (jpeg or png file)
          </strong>
        </div>
        <FolderAndFile 
          onFileClick={addIpaImageKeysHandler}
          withImageLink={true}
          withAllLink={false}
          withCheckbox={false}
          getUploadSelectedHandler={() => {}}
          filterMimeTypeList={['image/png', 'image/jpeg']}
        />
      </div>

    </div>
  );

  return (
    <Fragment>
      {/* {isLoading && <div>loading...loading...</div>} */}
      {editLinkImageStylizerBody}
    </Fragment>
  );
}

export default EditLinkImageStylizer;
