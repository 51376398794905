import { Fragment, useState, useEffect, useRef, } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import ConvertImage from "react-convert-image";
import numeral from 'numeral';
import download from 'downloadjs';

import ConvertUpload from './ConvertUpload';
import ConvertConvertList from './ConvertConvertList';
import ImageFileUpload from '../ImageFileUpload/ImageFileUpload';

import { useStore } from '../../hook-store/store';

import { loginPageLink, signupPageLink } from '../../App';

import { marks } from '../../utils/marks';

import './ImageConvert.css'
// import classes from './Footer.module.css';

// import { logoutHandler } from '../../utils/user';
// import { loginPageLink, signupPageLink } from '../../App';
import { BACKEND_URL } from '../../App';

export default function ConvertConvert(props) {
  // console.log('Layout.js-props', props);

  const [t] = useTranslation('translation');

  const navigate = useNavigate();

  const [store, dispatch] = useStore();
  const { 
    convertFiles,
    convertSelected,
    convertedFiles,
  } = store.imageConvertStore;
  // const { bucketName, allBucketObjects } = store.bucketObjectStore; 
  const { gLoading, isAuth } = store.uiStore;

  const [startConvert, setStartConvert] = useState(false);
  const [convertedB64, setConvertedB64] = useState('');
  const [convertInputs, setConvertInputs] = useState({
    targetFormat: { value: '', isValid: true },
    targetQuality: { value: 0.95, isValid: true },
  });
  const [showInputUploadModal, setShowInputUploadModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);


  const convertInputFiles = convertFiles.map(imageObj => {
    return imageObj.file;
  });

  
  useEffect(() => {
    if (gLoading) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  },[gLoading]);

  useEffect(() => {
    if (convertSelected) {
      setConvertedB64('');
    }
  },[convertSelected]);

  const setConvertInputsHandler = (inputIdentifier, enteredValue) => {

    if (inputIdentifier === 'targetQuality' && 
    !(Number(enteredValue) > 0 && Number(enteredValue) <= 1)
    ) {
      return;
    }

    if (inputIdentifier === 'targetFormat' && !enteredValue) {
      return;
    }

    setConvertInputs((curInputs) => {
      if (inputIdentifier === 'targetQuality') {
        return {
          ...curInputs,
          [inputIdentifier]: { value: Number(enteredValue), isValid: true },
        }
      } else {
        return {
          ...curInputs,
          [inputIdentifier]: { value: enteredValue, isValid: true },
        }
      }
    })
  };

  const handleConvertedImage = (url) => {
    // console.log(url);
    setStartConvert(false);
    base64ToFileHandler(url, convertSelected);

    setConvertedB64(url);
  }

  const base64ToFileHandler = async (b64, convertSelected) => {
    try {
      const fileData = b64.split(";")[0];
      const fileType = fileData.split(":")[1];
      const fileName = `convert-${Date.now()}.${fileType.split("/")[1]}`;

      const result = await fetch(b64);
      const resData = await result.blob();
      const file = new File([resData], fileName, { type: fileType });
      console.log(file, convertSelected);

      if (file) {

        const isIdInList = convertedFiles.find(element => {
          return element.id === convertSelected.id;
        });

        if (isIdInList) {
          const deletedList = convertedFiles.filter(element => {
            return element.id !== convertSelected.id;
          });

          const addedList = deletedList.concat({
            id: convertSelected.id,
            file: file,
          });

          dispatch('SET_CONVERTEDFILES', addedList);
        } else {
          const addedList = convertedFiles.concat({
            id: convertSelected.id,
            file: file,
          });

          dispatch('SET_CONVERTEDFILES', addedList);
        }

        // setCombinedFile(file);
      }

      return file;
    } catch (err) {
      console.log(err);
      throw err;
    }
  };


  const filesDownloadHandler = (resizedFiles) => {
    
    for (const resizedFile of resizedFiles) {
      download(
        resizedFile.file, 
        resizedFile.file.name, 
        resizedFile.file.type
      );
    }
  }



  console.log(convertInputs);

  let convertedImageFile;

  if (convertedB64) {
    const convertedImageInfo = convertedFiles.find(element => {
      return element.id === convertSelected.id;
    });

    if (convertedImageInfo) {
      convertedImageFile = convertedImageInfo.file;
    }
  }

  // console.log(convertedImageFile);

  let alreadyConvertedFile = false;

  if (convertSelected) {
    alreadyConvertedFile = convertedFiles.find(convertedFileObj => {
      return convertedFileObj.id === convertSelected.id;
    });
  }

  let imageConvertConvertBody;

  if (convertSelected) {
    imageConvertConvertBody = (
      <div>
        <section className='imageConvertParamElements'>
          <div className='imageConvertParamElement'>
            <div>
              {t('imageConvert05', 'Selected Image')}
            </div>
            <img 
              id='convert-selected'
              className='convertResizePreview'
              style={{maxHeight: "150px", maxWidth:"150px"}}
              src={URL.createObjectURL(convertSelected.file)}
              alt="convert selected file" 
              // onLoad={getImgDimension}
            />
            <div>
              <span>
                {convertSelected.file.type.split('/')[1]}
                {' '}
                {numeral(convertSelected.file.size).format('0.0 b')}
              </span>
            </div>
          </div>

          <div>
            <div className='imageConvertParamElement'>
              <div>
                <label>
                {t('imageConvert06', 'format')}
                </label>
              </div>
              <select className='imageConvertParamSelect'
                // name="pets" 
                // id="pet-select"
                onChange={(event) => { 
                  setConvertInputsHandler('targetFormat', event.target.value);
                }}
              >
                <option value="">
                {t('imageConvert07', 'Select target format')}
                </option>
                <option value="jpeg">JPEG</option>
                <option value="png">PNG</option>
                <option value="webp">WEBP</option>
              </select>
              <div>{convertInputs.targetFormat.value}</div>
            </div>
            <div className='imageConvertParamElement'>
              <div>
                <label>
                {t('imageConvert08', 'quality')} (0.1 ~ 1.0)
                </label>
              </div>
              <input className='inputBase'
                style={{maxWidth: "10rem"}}
                type="text"
                // placeholder='0.95'
                onChange={(event) => {
                  setConvertInputsHandler('targetQuality', event.target.value);
                }}
              />
              <div>{convertInputs.targetQuality.value}</div>
            </div>
          </div>
        </section>

        <div className='imageConvertElement'>
          <div>
            <button className='btnBase'
              style={{ fontSize: "1.25rem", fontWeight: "bold", padding:"0.5rem"}}
              disabled={!convertInputs.targetFormat.value || alreadyConvertedFile}
              onClick={() => {
                setStartConvert(true);
                setConvertedB64('');
              }}
            >
              {t('imageConvert09', 'Convert image format')}
            </button>
          </div>
          {convertSelected && startConvert && (
            <ConvertImage 
              image={URL.createObjectURL(convertSelected.file)}
              onConversion={handleConvertedImage}
              format={convertInputs.targetFormat.value ? convertInputs.targetFormat.value : 'jpeg'} //// jpeg, png
              quality={convertInputs.targetQuality.value ? convertInputs.targetQuality.value : 0.95}
            />
          )}
        </div>

        
        <div className='imageConvertElement'>
          {convertedImageFile && (
            <div>
              <div>
              {t('imageConvert10', 'Converted Image')}
              </div>
              <img 
                className='convertResizePreview'
                style={{maxHeight: "150px", maxWidth:"150px"}}
                src={convertedB64}
                alt="convert selected file" 
                // onLoad={getImgDimension}
              />
              <div>
                <span>
                  {convertedImageFile.type.split('/')[1]}
                  {' '}
                  {numeral(convertedImageFile.size).format('0.0 b')}
                </span>
              </div>
            </div>
          )}
        </div>
      </div>
    )
  }
 




  return (
    <Fragment>
      <div>
        {imageConvertConvertBody}
      </div>


      <div className='imageConvertElement'>
        <ConvertConvertList />
      </div>

      <div className='convertFileControllButtons'>
          <div>
            <button className='btnBase'
              disabled={convertedFiles.length === 0}
              onClick={() => { filesDownloadHandler(convertedFiles) }}
            >
              {marks.downloadMark} {t('imageConvert11', 'Download converted files')}
              {convertedFiles.length > 0 && (
                <span> ({convertedFiles.length})</span>
              )}
            </button>
          </div>
          <div>
            <ConvertUpload />
          </div>
     
      </div>

      <div className='imageConvertElement'>
        <button className='btnBase'
          disabled={convertInputFiles.length === 0}
          onClick={() => {
            setShowInputUploadModal(true);
          }}
        >
          {marks.uploadMark} {t('imageConvert19', 'Upload input files')}
          {' '}({convertInputFiles.length})
        </button>
   
        {showInputUploadModal && (
          <ImageFileUpload 
            files={convertInputFiles}
            onClose={() => { 
              setShowInputUploadModal(false) 
            }}
            uploadApp={'imageConvert'}
          />
        )}
      </div>





      {!isAuth && (
        <div>
          <p>
            {t('aboutPage.09', 'Login to upload and store files, or create account')}
          </p>

          <div className="convertResizeLoginButtons">
            <a href={loginPageLink}>
              <button className="btnBase">
                {marks.signInMrak} {t('aboutPage.10', 'Login')}
              </button>
            </a>
            <span> {t('aboutPage.11', 'or')} </span>
            <a href={signupPageLink}>
              <button className="btnBase">
                {marks.userPlusMrak} {t('aboutPage.12', 'Signup')}
              </button>
            </a>
          </div>
        </div>
      )}
    </Fragment>
  );
}

